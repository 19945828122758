(function ($) {
    var KEditor = $.keditor;
    var flog = KEditor.log;

    //CKEDITOR.disableAutoInline = true;


    // Text component
    // ---------------------------------------------------------------------
    KEditor.components['text'] = {
        options: {
             toolbar: [
                { name: 'clipboard', items: [ 'Cut', 'Copy', 'Paste', '-', 'Undo', 'Redo' ] },
            ],
            title: false,
            allowedContent: true, // DISABLES Advanced Content Filter. This is so templates with classes: allowed through
            bodyId: 'editor',
            //templates_replaceContent: false,
            //enterMode: 'P',
           // enterMode: CKEDITOR.ENTER_BR,
            forceEnterMode: true,
            //format_tags: 'p;h1',
            minimumChangeMilliseconds: 100
        },

        settingEnabled: true,

        settingTitle: KEditor.labels.editor_edit_snippet,

        init: function (contentArea, container, component, keditor) {
            flog('init "text" component', component);



            var self = this;
            self.keditor = keditor;

            var options = keditor.options;

            var componentContent = component.children('.keditor-component-content, .keditor-container-inner');

            //componentContent.prop('contenteditable', true);
            componentContent.on('input', function (e) {
                if (typeof options.onComponentChanged === 'function') {
                    options.onComponentChanged.call(contentArea, e, component);
                }

                if (typeof options.onContainerChanged === 'function') {
                    options.onContainerChanged.call(contentArea, e, container);
                }

                if (typeof options.onContentChanged === 'function') {
                    options.onContentChanged.call(contentArea, e);
                }
            });

            keditor.initEditables(componentContent, self.options);


        },

        getContent: function (component, keditor) {

            flog('getContent "text" component', component);

            var componentContent = component.find('.keditor-component-content');
            component.find(".keditor-toolbar").remove();
            component.find(".keditor-component-content").remove();
            componentContent.append(component.html());

            var id = componentContent.attr('id');

            return componentContent.html();

        },

        destroy: function (component, keditor) {
            flog('destroy "text" component', component);

            var id = component.find('.keditor-component-content').attr('id');

        },

        initSettingForm: function (component, form, keditor) {
            flog('initSettingForm "photo" component');

            var self = this;

            KEditor.builder.searchEditables(component, form, keditor, self.options, false);

        },
        initContainerForm: function (component, form, keditor) {
            flog('initContainerForm "photo" component');

            var self = this;

            KEditor.builder.searchEditablesContainer(component, form, keditor, self.options);

        },

        showSettingForm: function (form, component, keditor) {
            flog('showSettingForm "photo" component', component);
            var self = this;
        },

        // get toolbar options from element and extend options object
        getToolbarOptions: function(component, options){

            var self = this;
            var _options = jQuery.extend(true, {}, options)

            _toolbar = component.attr("data-wps-toolbar-items");

            if(_toolbar){
                _toolbar = _toolbar.split(",")
                 _options.toolbar.unshift(
                    {
                        name: 'basicstyles',
                        items: _toolbar
                    }
                )
            }

            return _options;
        }
    };

})(jQuery);
