(function ($) {
  var KEditor = $.keditor;


  //CKEDITOR.disableAutoInline = true;


  // Text component
  // ---------------------------------------------------------------------
  KEditor.builder = {

    // options for settings texteditors
    options: {
      extraPlugins: 'autogrow',
      autoGrow_minHeight: 100,
      height: 50,
      width: '75%',
      autoGrow_bottomSpace: 20
    },

    searchEditablesContainer: function (component, form, keditor, options) {






      this.searchEditables(component, form, keditor, options, true);





    },
    searchEditables: function (component, form, keditor, options, isContainer) {
      var self = this;

      console.error("THIS IS THE START");
      var _startGlobal = Date.now();


      self.options = $.extend(true, self.options, options);

      var editable; //= $(component).find("[data-wps-edit]");
      //only templates with classname "pgs-cb-template"
      var templates=[];
      var templates2=[];
      self.keditor = keditor;

      if(isContainer){
        var editableAll = $(component).find('[data-wps-edit]');
        templates = $(component).find("template.pgs-co-template");
        templates2 = $(component).find("template.pgs-co-template2");
        editable = [];
        $.each(editableAll, function (index, el) {
          // exclude all editable inside data-wps-type="container-content"
          if($(this).parent().closest('[data-wps-container="true"][data-wps-type="container-content"]').length==0){
              editable.push(this)
            }
        })
      }else{
        editable = $(component).find("[data-wps-edit]");
        templates = $(component).find("template.pgs-cb-template");
        templates2 = $(component).find("template.pgs-cb-template2");
      }


      var numbers = [],
        _dom;


      // console.log("editable", editable)

      $.each(editable, function (index, el) {
        if ($(el).closest(".swiper-slide-duplicate").length > 0) return;
        numbers.push({
          group: $(el).attr("data-wps-edit"),
          el: el
        })

      })

      numbers.sort(function (a, b) {

        // get group number of a
        var iend = a.group.indexOf("-"); //this finds the first occurrence of "-"
        if (iend != -1) {
          _groupA = a.group.substring(iend + 1, a.group.Length); //this will give the group numbers
        } else {
          _groupA = a.group;
        }

        // get group number of b
        var iend = b.group.indexOf("-"); //this finds the first occurrence of "-"
        if (iend != -1) {
          _groupB = b.group.substring(iend + 1, b.group.Length); //this will give the group numbers
        } else {
          _groupB = b.group;
        }

        return _groupA - _groupB;

      });


      self.newGroup = 0;

      var fragment = $(document.createDocumentFragment());



      var _group,
        _group_before = "",
        _length = numbers.length,
        _fragment;



      $.each(numbers, function (index, obj) {
        // get group number
        var iend = obj.group.indexOf("-"); //this finds the first occurrence of "-"
        if (iend != -1) {
          _group = obj.group.substring(iend + 1, obj.group.Length); //this will give the group numbers
        } else {
          _group = obj.group;
        }

        obj.group = _group;

      })




      console.log("numbers", numbers);
      // add each editable to the fragment
      $.each(numbers, function (index, obj) {
        //console.warn("each number", obj.el);
        _group = obj.group;
        tag = obj.el.tagName.toLowerCase();
        var _container;


        // if new group generate framgemt, where all elements from that group are inserted
        if (_group != _group_before) {
          _fragment = $(document.createDocumentFragment());
          if (_group.indexOf("false") !== -1) _group = "";
          var timestamp = Date.now();
          //  _fragment.append("<a data-toggle='collapse' class='collapsed' data-target='#collabsible_"+timestamp+"'><div class='collabsible_h4' ><i class='fa fa-chevron-down' aria-hidden='true'></i>"+_group+"</div></a><div id='collabsible_"+timestamp+"' class='collapse snippetBlock' data-class='"+_group.replace(/ /g,"_")+"'></div>");
          //_fragment.append("<a data-toggle2='collapse' class='collapsed collapsibleFake' data-target='#collabsible_" + timestamp + "'><div class='collabsible_h4' ><i class='fa fa-chevron-down' aria-hidden='true'></i>" + _group + "</div></a><div id='collabsible_" + timestamp + "' class='collapse snippetBlock' data-class='" + _group.replace(/ /g, "_") + "'></div>");
          var _search = $("<i class='fa fa-search highlight-button' aria-hidden='true'></i>");
          var numbers_copy = $.extend(true, [], numbers);

          if (_group == "settings") _search = $("");
          var all = $.grep(numbers_copy, function (e) {
            return e.group == _group;
          });

          _search.data("ref", {
            "el": all
          })
          _search.mouseenter(function (ev) {
            var e = $(this).data("ref").el;
            $.each(e, function (index, _el) {
              $(_el.el).addClass("wbmg_editor_highlight");
            })
          })
          _search.mouseleave(function (ev) {
            var e = $(this).data("ref").el;
            $.each(e, function (index, _el) {
              $(_el.el).removeClass("wbmg_editor_highlight");
            })
          })
          var _dropdown = $("<div class='collapse_wrapper'><a data-toggle='collapse' class='collapsed' data-group='" + _group.replace(/ /g, "_").toLowerCase() + "' data-target='#collabsible_" + timestamp + "'><div class='collabsible_h4' ><i class='fa fa-angle-down' aria-hidden='true'></i>" + _group + "</div></a><div id='collabsible_" + timestamp + "' class='collapse snippetBlock' data-class='" + _group.replace(/ /g, "_").toLowerCase() + "'></div></div>")
          _dropdown.find("a").addBack("a").prepend(_search);
          _fragment.append(_dropdown);
        }

        _container = _fragment.find(".snippetBlock");

        // if a snippetBlock with that name is already available, insert there
        var availableFrag = fragment.find('*[data-class="' + _group.replace(/ /g, "_").toLowerCase() + '"]');
        if (availableFrag.length > 0) {
          // console.log(availableFrag)
          _container = availableFrag;

        }


        self.insertOption(obj.el, _container)

        _group_before = _group;

        // only if an snippetBlock with that name ist not available
        if (availableFrag.length == 0) {

          //add the elements _fragment to the framgment
          if (index < _length - 1) {
            if (numbers[index + 1].group != _group) {
              fragment.append(_fragment)
            }
          } else {
            fragment.append(_fragment)
          }
        }


      })



      //open close collapsible
      $(fragment).find(".collapsibleFake").click(function () {
        // console.log("clcik")
        self.keditor.body.find($(this).attr("data-target")).toggleClass("collapse")
      })



      // find templates and add buttons
      if (templates.length > 0) {
        $.each(templates, function (index, template) {
          _templateName = $(template).attr("data-wps-template-name") || "";
          _templateId = $(template).attr("pgs-ams-template") || "";
          _templateId1 = $(template).attr("data-wps-template-id").replace(/ /g, "_").toLowerCase() || "";
          _dom = $("<div class='button_group'><button class='btn btn-dark addItem'>Add " + _templateName + "</button><button class='btn btn-outline-dark deleteItem'>Remove last " + _templateName + "</button></div>");
          _dom.data("ref", {
            "parent": $(template).parent(),
            "id": $(template).attr("data-wps-template-id")
          })
          var component_clone;

          _dom.find("button.addItem").click(function () {

            var _component = component_clone || component; // because component was cloned and loses dom node

            var _id = $($(this).parent()).data("ref").id;

            var _templates = $(_component).find("template.pgs-cb-template");

            //component_clone = _component.clone(true, true);
            component_clone = _component;

            $.each(_templates, function (index, template) {
              // only use the template with the right ID
              if (_id && $(template).attr('data-wps-template-id') != _id) return;
              templateContent = $($(template).html());
              templateID = $(templateContent).attr("pgs-ams-template");

              templateDivs = $(_component).find("[pgs-ams-template='" + templateID + "']").addBack("[pgs-ams-template='" + templateID + "']").not(".swiper-slide-duplicate");
              var number_array = [];
              $.each(templateDivs, function (index, val) {
                //iterate through array or object
                number_array.push($(val).data("wpsIndex"));

              });
              var templateDIvsLength = Math.max.apply(this, number_array);

              //  if(_id) lastTemplate = $(_component).find("[pgs-ams-template='"+templateID+"'][data-wps-index='"+templateDivs.length+"']").not(".swiper-slide-duplicate")
              //else
              var lastTemplate;
              if (templateDivs.length > 0) lastTemplate = templateDivs[templateDivs.length - 1];
              //console.log("lastTemplate", lastTemplate);

              if (lastTemplate == undefined || lastTemplate.length == 0) {
                lastTemplate = $(_component).find("template[data-wps-template-id='" + templateID + "']");
                templateDIvsLength = 0;
              }
              //console.log("lastTemplate", lastTemplate);

              var clone = $(templateContent).clone(true, true);
              var outerHTML = clone.prop('outerHTML').replace(/{i}/g, templateDIvsLength + 1);

              $(outerHTML).insertAfter(lastTemplate)
            })




            _component.replaceWith(component_clone);

            // could that be done better?
            var contentArea = component_clone.closest(".keditor-content-area");
            var container = component_clone.closest(".keditor-container");

            //reinit component
            keditor.reinitComponent(contentArea, container, component_clone);


            //really bad, but only works like this ....?
            setTimeout(function () {
              self.searchEditables(component_clone, form, keditor, options);
            }, 10);

          })

          _dom.find("button.deleteItem").click(function () {

            var _component = component; // because component was cloned and loses dom node
            var lastTemplate,
              _img,
              _id = $($(this).parent()).data("ref").id;

            var _templates = $(_component).find("template.pgs-cb-template");


            $.each(_templates, function (index, template) {

              if (_id && $(template).attr('data-wps-template-id') != _id) return;
              templateContent = $($(template).html());
              templateID = $(templateContent).attr("pgs-ams-template");



              templateDivs = $(_component).find("[pgs-ams-template='" + templateID + "']").addBack("[pgs-ams-template='" + templateID + "']");
              lastTemplate = $(_component).find("[pgs-ams-template='" + templateID + "']").last();


              // console.log("lastTemplate",lastTemplate[0])

              // find images and delete them
              /*  _imgs = $(lastTemplate).find("img[data-wps-edit]")
                $.each(_imgs, function(index, el) {
                  var _id = $(el).attr("data-id");
                  // delete the previous selected image from the server
                  if (_id) {
                    module.fn.fileDelete({
                      file: _id,
                      callback: function(ev) {
                        console.log("deleted", ev)
                      }
                    });
                  }
                });*/

              // console.log("remove",$(lastTemplate) )

              //if(templateDivs.length>1) $(lastTemplate).remove();
              $(lastTemplate).remove();

            })



            // could that be done better?
            var contentArea = component.closest(".keditor-content-area");
            var container = component.closest(".keditor-container");

            //reinit component
            keditor.reinitComponent(contentArea, container, component);

            self.searchEditables(component, form, keditor, options);
          })
          fragment.append(_dom);
          if (templates2.length > 0) {

            $.each(templates2, function (index, template2) {
              _templateName2 = $(template2).attr("data-wps-template-name") || "";
              _parentTemplateName2 = $(template2).attr("data-wps-template-parent-name").replace(/ /g, "_").toLowerCase() || "";
              _parentTemplateId2 = $(template2).attr("data-wps-template-parent-id").replace(/ /g, "_").toLowerCase() || "";
              _templateId2 = $(template2).attr("pgs-ams-template") || "";
              if(_parentTemplateId2!=_templateId1) return;


              // console.log("ID", _parentTemplateId2);
              // console.log("find template", $(component).find('[pgs-ams-template="' + _parentTemplateId2 + '"]'))
              $.each($(component).find('[pgs-ams-template="' + _parentTemplateId2 + '"]'), function (index, template3) {
                var _index = $(this).attr("data-wps-index");
                _dom2 = $("<div class='button_group'><button class='btn btn-dark addItem'>Add " + _templateName2 + "</button><button class='btn btn-outline-dark deleteItem'>Remove last " + _templateName2 + "</button></div>");
                _dom2.data("ref", {
                  "parent": $(template2).parent(),
                  "id": _index,
                  "parentTemplateId": _parentTemplateId2
                })

                var component_clone2;

                _dom2.find("button.addItem").click(function () {
                  var _component = component_clone || component; // because component was cloned and loses dom node
                  var _panel = $(this).closest("#keditor-setting-panel");

                  var _id = $($(this).parent()).data("ref").id;
                  var _parentTemplateId = $($(this).parent()).data("ref").parentTemplateId;


                  component_clone = _component;

                  $.each(templates2, function (index, template) {
                    // only use the template with the right ID
                    // if (_id && $(template).attr('data-wps-template-id') != _id) return;
                    templateContent = $($(template).html());
                    templateID = $(templateContent).attr("pgs-ams-template");

                    templateDivs = $(_component).find("[pgs-ams-template='" + _parentTemplateId + "'][data-wps-index='" + _id + "']").find("[pgs-ams-template='" + templateID + "']").addBack("[pgs-ams-template='" + templateID + "']").not(".swiper-slide-duplicate");
                    var number_array = [];
                    $.each(templateDivs, function (index, val) {
                      //iterate through array or object
                      number_array.push($(val).data("wpsIndex"));

                    });
                    var templateDIvsLength = Math.max.apply(this, number_array);

                    var lastTemplate;
                    if (templateDivs.length > 0) lastTemplate = templateDivs.last();

                    if (lastTemplate == undefined || lastTemplate.length == 0) {
                      lastTemplate = $(_component).find("template[data-wps-template-id='" + templateID + "']");
                      templateDIvsLength = 0;
                    }

                    var clone = $(templateContent).clone(true, true);
                    var outerHTML = clone.prop('outerHTML').replace(/{i}/g, _id).replace(/{j}/g, templateDIvsLength + 1);

                    $(outerHTML).insertAfter(lastTemplate)
                  })

                  _component.replaceWith(component_clone);

                  // could that be done better?
                  var contentArea = component_clone.closest(".keditor-content-area");
                  var container = component_clone.closest(".keditor-container");

                  //reinit component
                  keditor.reinitComponent(contentArea, container, component_clone);

                  // save the opened collapsibles
                  var notCollapsed = _panel.find(".snippetBlock.collapse.show");
                  var _savedDataClass = [];
                  notCollapsed.each(function() {
                    _savedDataClass.push($(this).attr("data-class"));
                  });

                  //really bad, but only works like this ....?
                  setTimeout(function () {
                    self.searchEditables(component_clone, form, keditor, options);
                    //open the opened collapsibles again
                    setTimeout(function () {
                      $.each(_savedDataClass, function( index, value ) {
                        _panel.find(".collapse.snippetBlock[data-class='"+value+"']").addClass("show");
                      });

                    }, 10);
                  }, 10);

                })

                _dom2.find("button.deleteItem").click(function () {

                  var _component = component; // because component was cloned and loses dom node
                  var _panel = $(this).closest("#keditor-setting-panel");
                  var lastTemplate,
                    _id = $($(this).parent()).data("ref").id,
                    _parentTemplateId = $($(this).parent()).data("ref").parentTemplateId;

                  $.each(templates2, function (index, template) {

                    //if (_id && $(template).attr('data-wps-template-id') != _id) return;
                    templateContent = $($(template).html());
                    templateID = $(templateContent).attr("pgs-ams-template");

                    templateDivs = $(_component).find("[pgs-ams-template='" + _parentTemplateId + "'][data-wps-index='" + _id + "']").find("[pgs-ams-template='" + templateID + "']").addBack("[pgs-ams-template='" + templateID + "']").not(".swiper-slide-duplicate");

                    if (templateDivs.length > 1) {
                      //templateDivs = $(_component).find("[pgs-ams-template='" + templateID + "']").addBack("[pgs-ams-template='" + templateID + "']");
                      lastTemplate = templateDivs.last();

                      //if(templateDivs.length>1) $(lastTemplate).remove();
                      $(lastTemplate).remove();
                    }


                  })



                  // could that be done better?
                  var contentArea = component.closest(".keditor-content-area");
                  var container = component.closest(".keditor-container");

                  //reinit component
                  keditor.reinitComponent(contentArea, container, component);

                  // save the opened collapsibles
                  var notCollapsed = _panel.find(".snippetBlock.collapse.show");
                  var _savedDataClass = [];
                  notCollapsed.each(function() {
                    _savedDataClass.push($(this).attr("data-class"));
                  });

                  //really bad, but only works like this ....?
                  self.searchEditables(component, form, keditor, options);
                  //open the opened collapsibles again
                  setTimeout(function () {
                    $.each(_savedDataClass, function( index, value ) {
                      _panel.find(".collapse.snippetBlock[data-class='"+value+"']").addClass("show");
                    });

                  }, 10);



                })
                fragment.find('*[data-class="' + _parentTemplateName2 + '_' + _index + '"]').prepend(_dom2);
              })


            })


            fragment.append(_dom);

          }

          var _up = $("<i class='fa fa-angle-up' aria-hidden='true'></i>"),
            _down = $("<i class='fa fa-angle-down' aria-hidden='true'></i>"),
            _delete = $("<i class='fa fa-trash' aria-hidden='true'></i>");
          $.each(templates, function (index, template) {
            templateName = $(template).attr("data-wps-template-name") || ""; //e.g. Index
            templateID = $(template).attr("data-wps-template-id") || "";
            templateDivs = $(component).find("[pgs-ams-template='" + templateID + "']").not(".swiper-slide-duplicate");
            templateDIvsLength = templateDivs.length;

            $.each(templateDivs, function (index, editor_template) {
              _groupCollapsibles = fragment.find("[data-group='" + templateName.toLowerCase().replace(/ /g, "_") + "_" + $(editor_template).attr("data-wps-index") + "']");

              $.each(_groupCollapsibles, function (index, _collapse) {
                _up.data("ref", {
                  "template": $(editor_template),
                  "self": $(_collapse),
                  "templateID": templateID
                })
                _down.data("ref", {
                  "template": $(editor_template),
                  "self": $(_collapse),
                  "templateID": templateID
                })
                var _container = $("<div class='template_move'></div>");
                var _up_copy = _up.clone(true),
                  _down_copy = _down.clone(true);

                _up_copy.click(function (e) {
                  var that = this;
                  e.stopPropagation();
                  var e = $(this).data("ref").template,
                    templateID = $(this).data("ref").templateID;

                  templateDivs = $(component).find("[pgs-ams-template='" + templateID + "']").not(".swiper-slide-duplicate");
                  var current_index = templateDivs.index(e);
                  e.insertBefore(templateDivs[current_index - 1]);
                  //arraymove(templateDivs,current_index,current_index-2);
                  //self.keditor.save();
                  // could that be done better?
                  var contentArea = component.closest(".keditor-content-area");
                  var container = component.closest(".keditor-container");

                  //reinit component
                  keditor.reinitComponent(contentArea, container, component);

                  var elm = $(that).closest(".collapse_wrapper");
                  var prev = elm.prev(".template_move_wrapper");
                  if(prev.length>0) elm.insertBefore(prev);
                })
                _down_copy.click(function (e) {
                  var that = this;
                  e.stopPropagation();
                  var e = $(this).data("ref").template,
                    templateID = $(this).data("ref").templateID;
                  templateDivs = $(component).find("[pgs-ams-template='" + templateID + "']").not(".swiper-slide-duplicate");

                  var current_index = templateDivs.index(e);

                  e.insertAfter(templateDivs[current_index + 1]);
                  //self.keditor.save();
                  // could that be done better?
                  var contentArea = component.closest(".keditor-content-area");
                  var container = component.closest(".keditor-container");

                  //reinit component
                  keditor.reinitComponent(contentArea, container, component);

                  var elm = $(that).closest(".collapse_wrapper");
                  var next = elm.next(".template_move_wrapper");
                  if(next.length>0) elm.insertAfter(next);
                })
                _container.append(_up_copy).append(_down_copy);

                //$(_collapse).append(_container);

                _delete.data("ref", {
                  "template": $(editor_template),
                  "self": $(_collapse),
                  "templateID": templateID
                })
                //var _container = $("<div class='template_delete'></div>");
                var _delete_copy = _delete.clone(true);

                _delete_copy.click(function (e) {
                  e.stopPropagation();
                  var e = $(this).data("ref").template,
                    _self = $(this).data("ref").self;


                  e.remove();
                  _self.remove();
                  //self.keditor.save();
                  // could that be done better?
                  var contentArea = component.closest(".keditor-content-area");
                  var container = component.closest(".keditor-container");

                  //reinit component
                  keditor.reinitComponent(contentArea, container, component);


                  //really bad, but only works like this ....?
                  setTimeout(function () {
                    self.searchEditables(component, form, keditor, options);
                  }, 10);

                })
                _container.append(_delete_copy);
                $(_collapse).closest(".collapse_wrapper").addClass("template_move_wrapper");
                $(_collapse).append(_container);
              })


            })

            //_groupCollapsibles = fragment.find("a[data-class='"+_templateName+"_"+_templateId+"']");


          })


          function arraymove(arr, fromIndex, toIndex) {
            var element = arr[fromIndex];
            arr.splice(fromIndex, 1);
            arr.splice(toIndex, 0, element);
          }
        })
      }

      //Add animation settings
      var _aos = $(component).find("[data-wps-aos]").not($(component).find('.keditor-sub-container-content[data-wps-type="container-content"] [data-wps-aos]')),
        _aos_options = ["none", "fade-in", "fade-up", "fade-down", "fade-right", "fade-left", "shake", "bounce", "flip-left", "flip-right", "flip-up", "flip-down", "zoom-in", "zoom-out", "scale-right", "scale-left", "scale-up", "scale-down"];
      $.each(_aos, function (index, el) {

        _aosDiv = "";
        var _val,
          _currentAttr = $(el).attr('data-aos'),
          _currentClass = $(el).attr('data-wps-aos'),
          _currentDelay = $(el).attr('data-aos-delay') || 0;

        if (_currentClass == "true") _currentClass = "settings";

        var title= "Add Animation";
        if($(el).hasClass("bg-img-overlay")){
          title= "Add Overlay Animation";
        }


        _aosDiv = '<ams-fragment><label><div class="ams-ftitle">'+title+'</div></label><t-ffield><select class="classChangeAos">';
        $.each(_aos_options, function (index, val) {
          /* iterate through array or object */
          if (val == _currentAttr) {
            _aosDiv += "<option selected value='" + val + "'>" + val + "</option>";
          } else {
            _aosDiv += "<option value='" + val + "'>" + val + "</option>";
          }
        });
        _aosDiv += '</select></t-ffield><label><div class="ams-ftitle">Delay (in ms)</div></label><t-ffield><input value="' + _currentDelay + '" class="aos_delay" type="text">';
        _aosDiv += '</t-ffield></ams-fragment>';

        _aosDiv = $(_aosDiv);

        //aos class change event
        _aosDiv.find("select.classChangeAos").on('change', function () {
          $(el).attr('data-aos', this.value);
          self.keditor.save();
        })
        //aos class change event
        _aosDiv.find("input.aos_delay").on('input', self.debounce(function () {
          $(el).attr('data-aos-delay', this.value);
          self.keditor.save();
        }, 500));
        console.log("_currentClass", _currentClass.replace(/ /g, "_").toLowerCase())
        fragment.find('*[data-class="' + _currentClass.replace(/ /g, "_").toLowerCase() + '"]').prepend(_aosDiv)
      })


      // Add print class settings
      _aos_options = {
          "Show":"d-print-show",
          "Hide in screen":"d-screen-none",
          "Hide in print":"d-print-none"
        }
        _printDiv = "";
        var _val,

        _currentClasses = $(component).attr('class');

        _printDiv = '<ams-fragment><label><div class="ams-ftitle">Display options</div></label><t-ffield><select class="classChangePrint">';
        $.each(_aos_options, function (index, val) {
          /* iterate through array or object */
          // if val is in one of the current classes  add selected
          if (_currentClasses.indexOf(val) > -1) {
            _printDiv += "<option selected value='" + val + "'>" + index + "</option>";
          } else {
            _printDiv += "<option value='" + val + "'>" + index + "</option>";
          }
        });
        _printDiv += '</select></t-ffield></ams-fragment>';
        _printDiv = $(_printDiv);
        //aos class change event
        _printDiv.find("select.classChangePrint").on('change', function () {
          // delete all classes from _aos_aoption
          $.each(_aos_options, function (index, val) {
            $(component).removeClass(val);
          });
          $(component).addClass(this.value);
          self.keditor.save();
        })

        fragment.find('*[data-class="settings"]').append(_printDiv)



      //Add anchor settings
      // find only the anchors from the container (not from the snippets inside the container)
      var _anchor = $(component).find("[data-wps-anchor]").not($(component).find('.keditor-sub-container-content[data-wps-type="container-content"] [data-wps-anchor]'))
      $.each(_anchor, function (index, el) {
        _anchorDiv = "";
        // console.log("el", el)
        var _val,
          _currentAttr = $(el).attr('id') || "",
          _currentClass = $(el).attr('data-wps-anchor');
        if (_currentClass == "true") _currentClass = "settings";
        _anchorDiv = '<ams-fragment><label><div class="ams-ftitle">Add Anchor Link</div></label><t-ffield># <input value="' + _currentAttr + '" class="anchor" type="text"><t-ffield>';
        _anchorDiv += '</t-ffield></ams-fragment>';
        _anchorDiv = $(_anchorDiv);
        //aos class change event
        _anchorDiv.find("input.anchor").on('input', self.debounce(function () {
          $(el).attr('id', this.value);
          self.keditor.save();
        }, 500));
        fragment.find('*[data-class="' + _currentClass.replace(/ /g, "_") + '"]').prepend(_anchorDiv)
      })

      //Add context settings
      // find only the context attributes from the container (not from the snippets inside the container)
      var _contextDiv = $(component).find("[data-wps-context]")

      var _contextDiv2 = $(component);

      _contextDiv = _contextDiv.add(_contextDiv2);

      if(self.keditor.options.personalizedData.length>0){
        $.each(_contextDiv, function (index, el) {
          _contextDiv = "";
          // console.log("el", el)

          var _val,
            _currentAttr = $(el).attr('id') || "",
            _currentClass = $(el).attr('data-wps-context'),
            _context = $(el).attr('data-context') || null;


          if (_currentClass == "true" || !_currentClass) _currentClass = "settings";

          var _buttonText = "Add context";
          if (_context) _buttonText = "Edit context";


          _contextDiv = '<ams-fragment><label><div class="ams-ftitle">Context</div></label><t-ffield><button class="context-btn btn border-dark btn-sm"><i class="fa  fa-flask mr-1" aria-hidden="true"></i>'+_buttonText+'</button>';

          _contextDiv += '</t-ffield></ams-fragment>';

          _contextDiv = $(_contextDiv);


          //aos class change event
          _contextDiv.find("button.context-btn").on('click', function () {
            _context = $(el).attr('data-context') || null;
            if(_context) {
              console.log("_context", _context);
              var parsedContext = JSON.parse(_context);
              console.log("parsedContext", parsedContext);
              if(!Array.isArray(parsedContext)) {
                parsedContext = [parsedContext];
              }
            }else{
              parsedContext = [];
            }

            console.log("parsedContext", parsedContext);
            if (typeof self.keditor.options.callFromKeditor === 'function') self.keditor.options.callFromKeditor("contextModal",
                parsedContext,
            function (data) {
              console.log("data", data);
              if(data.length > 0) {
                $(el).attr('data-context',  JSON.stringify(data));
                self.keditor.save();
              }else{
                $(el).removeAttr('data-context');
                self.keditor.save();
              }
            })

          });

          fragment.find('*[data-class="' + _currentClass.replace(/ /g, "_") + '"]').prepend(_contextDiv)
        })
      }

      // add TEF filter options to
      if(self.keditor.groupSettings.editorSettings && self.keditor.groupSettings.editorSettings.telefonica_filter){
        //Add anchor settings
        var _anchor = $(component).find("[data-wps-anchor]").not($(component).find('.keditor-sub-container-content[data-wps-type="container-content"] [data-wps-anchor]'))

        $.each(_anchor, function (index, el) {
          _tef_filterDiv = "";

          var _currentClass = $(el).attr('data-wps-anchor');

          if (_currentClass == "true") _currentClass = "settings";

          var _filter = {
            "tef-user0"     : "Neukunde",
            "tef-user1"     : "Junge Leute",
            "tef-user2"     : "60 Plus",
            "tef-user3"     : "Selbstständig",
            "tef-user4"     : "o2 Kunde",
            "tef-tv-tuerk"  : "Türkisch",
            "tef-tv-deutsch": "Deutsch",
            "wbmg-hide"     : "Hide snippet on start",
          }

          _tef_filterDiv = '<ams-fragment><label><div class="ams-ftitle">Set Filter</div></label>';

          $.each(_filter, function (index, val) {
            var timestamp = Math.random().toString().slice(2, 11);
            var isChecked = "";
            if($(el).hasClass(index)) isChecked='checked="true"';
            _tef_filterDiv+= '<t-ffield><input ' + isChecked + ' name="'+index+'"  class="tef_filter" type="checkbox" id="styled-checkbox-3_' + timestamp + '" placeholder="" value="'+index+'" spellcheck="false" autocorrect="off"><label for="styled-checkbox-3_' + timestamp + '">'+val+'</label></t-ffield>'
          })
          _tef_filterDiv += '</t-ffield></ams-fragment>';
          _tef_filterDiv = $(_tef_filterDiv);

            //change event
            _tef_filterDiv.find("input.tef_filter").on('change', function () {
            if($(el).hasClass(this.value)){
              $(el).removeClass(this.value);
            }else{
              $(el).addClass(this.value);
            }
            self.keditor.save();
          });

          fragment.find('*[data-class="' + _currentClass.replace(/ /g, "_") + '"]').prepend(_tef_filterDiv)
        })


       // Add translate class settings
        var _weglot_options = {
          "Don't translate":"",
          "Translate":"weglot_translate",
        }
        var _translateWeglot = "";
        var _val,
        _currentClasses = $(component).attr('class');


        console.log("_currentClasses",  _currentClasses)

        _translateWeglot = '<ams-fragment><label><div class="ams-ftitle">Translate with Weglot</div></label><t-ffield><select class="classChangeWeglot">';
        $.each(_weglot_options, function (index, val) {
          /* iterate through array or object */
          // if val is in one of the current classes  add selected
          console.log("_currentClasses",  _currentClasses, val)
          console.log("_currentClasses.indexOf(val)",  _currentClasses.indexOf(val))
          if (_currentClasses.indexOf(val) > -1 && _currentClasses.indexOf(val) != 0) {
            _translateWeglot += "<option selected value='" + val + "'>" + index + "</option>";
          } else {
            _translateWeglot += "<option value='" + val + "'>" + index + "</option>";
          }
        });
        _translateWeglot += '</select></t-ffield></ams-fragment>';
        _translateWeglot = $(_translateWeglot);
        //aos class change event
        _translateWeglot.find("select.classChangeWeglot").on('change', function () {
          // delete all classes from _aos_aoption
          $.each(_weglot_options, function (index, val) {
            $(component).removeClass(val);
          });
          $(component).addClass(this.value);
          self.keditor.save();
        })

        fragment.find('*[data-class="settings"]').append(_translateWeglot)
      }





      form.html(fragment);

      var _endGlobal = Date.now();
      console.warn(`Execution time global: ${_endGlobal - _startGlobal} ms`);

    },


    insertOption: function (el, form) {
      var self = this;




      function setupClassChange(number, el) {
        var attrName = number ? `data-wps-class-change${number}` : 'data-wps-class-change';
        var divVar = number ? `_classChange${number}_div` : '_classChange_div';
        var classChangeName = number ? `classChange${number}` : 'classChange';
        var result = { div: '' };

        var classChange = $(el).attr(attrName);
        if (!classChange) {
          return result;
        }

        classChange = checkClassChange(classChange);
        var options = classChange.split('|');

        result.div = '<t-ffield><select class="' + classChangeName + '">';

        // Add regular options
        $.each(options, function(index, val) {
          var optionParts = val.split('=');
          if ($(el).hasClass(optionParts[1])) {
            result.div += `<option selected value="${optionParts[1]}">${optionParts[0]}</option>`;
          } else {
            result.div += `<option value="${optionParts[1]}">${optionParts[0]}</option>`;
          }
        });

        // Handle upgrade options if they exist
        var upgrade = self.keditor.groupSettings?.editorSettings?.upgrade || null;
        if (upgrade && upgrade[attrName]) {
          $.each(upgrade[attrName], function(key, val) {
            if ($(el).hasClass(key)) {
              var upgradeOptions = val.split('|');
              $.each(upgradeOptions, function(index, val) {
                if (val === "") return;
                var optionParts = val.split('=');
                if ($(el).hasClass(optionParts[1])) {
                  result.div += `<option selected value="${optionParts[1]}">${optionParts[0]}</option>`;
                } else {
                  result.div += `<option value="${optionParts[1]}">${optionParts[0]}</option>`;
                }
              });
            }
          });
        }

        result.div += '</select></t-ffield>';
        return result;
      }

      // Replace multiple classChange blocks with function calls
      var classChanges = [];
      for (var i = 0; i <= 8; i++) {
        var number = i === 0 ? '' : i;
        var result = setupClassChange(number, el);
        classChanges[i] = result.div;
      }

      _classChange_div = classChanges[0];
      _classChange2_div = classChanges[2];
      _classChange3_div = classChanges[3];
      _classChange4_div = classChanges[4];
      _classChange5_div = classChanges[5];
      _classChange6_div = classChanges[6];
      _classChange7_div = classChanges[7];
      _classChange8_div = classChanges[8];

      // Add event handlers for class changes
      function setupClassChangeHandlers(_html) {
        for (var i = 0; i <= 8; i++) {
          var className = i === 0 ? 'classChange' : 'classChange' + i;
          _html.find('.' + className).on('change', function() {
            var select = $(this);
            var oldValue = select.find('option').filter(function() {
              return $(el).hasClass(this.value);
            }).val();

            if (oldValue) {
              $(el).removeClass(oldValue);
            }

            var newValue = select.val();
            if (newValue) {
              // Check for data-wps-class-change-attr
              if ($(el).attr('data-wps-class-change-attr')) {
                var attrName = $(el).attr('data-wps-class-change-attr');
                if (attrName) {
                  $(el).attr(attrName, newValue);
                }
              } else {
                $(el).addClass(newValue);
              }

              // Handle special class cases
              switch (newValue) {
                case "universal_tabs1_js":
                  $(el).find('.universal_js_video').addClass('col-md-12').removeClass('col-md-9 order-2 order-md-1');
                  $(el).find('.universal_js_list').addClass('col-md-12').removeClass('col-md-3 order-1 order-md-2');
                  $(el).find('.universal_js_list .row > div').addClass('col-md-3').removeClass('col-md-12');
                  break;
                case "universal_tabs2_js":
                  $(el).find('.universal_js_video').removeClass('col-md-12').addClass('col-md-9 order-2 order-md-1');
                  $(el).find('.universal_js_list').removeClass('col-md-12').addClass('col-md-3 order-1 order-md-2');
                  $(el).find('.universal_js_list .row > div').removeClass('col-md-3').addClass('col-md-12');
                  break;
                case "cta05_01_js":
                  $(el).find('.cta05_text').addClass('order-0').removeClass('order-2');
                  $(el).find('.cta05_image').addClass('mt-3 mt-md-0').removeClass('mb-3 mb-md-0');
                  break;
                case "cta05_02_js":
                  $(el).find('.cta05_text').addClass('order-2').removeClass('order-0');
                  $(el).find('.cta05_image').addClass('mb-3 mb-md-0').removeClass('mt-3 mt-md-0');
                  break;
              }
            }

            self.keditor.save();
          });
        }
      }

      function setupImageOptions(el, _html) {
        // Image link functionality
        _html.find("input.image_link").on('change', function() {
          if (this.checked) {
            if ($(el).closest("img-cover").length > 0) {
              $(el).closest("img-cover").wrap("<span>");
              $(el).closest("img-cover").parent().addClass("link_image");
            } else {
              $(el).wrap("<span>");
              $(el).parent().addClass("link_image");
            }
            $(el).parent().append('<a href="" class="stretched-link"></a>');
            $(this).closest(".image_link_container").find(".image_target").closest("t-ffield").removeClass('d-none');
            $(this).closest(".image_link_container").find(".image_link_input").closest("t-ffield").removeClass('d-none');
          } else {
            $(el).closest(".link_image").children().unwrap();
            $(el).closest(".link_image").removeClass("link_image");
            $(el).parent().find(".stretched-link").remove();
            $(this).closest(".image_link_container").find(".image_target").closest("t-ffield").addClass('d-none');
            $(this).closest(".image_link_container").find(".image_link_input").closest("t-ffield").addClass('d-none');
          }
          self.keditor.save();
        });

        // Image target (open in new tab)
        _html.find("input.image_target").on('change', function() {
          if (this.checked) {
            $(el).parent().find(".stretched-link").attr("target", "_blank");
          } else {
            $(el).parent().find(".stretched-link").removeAttr("target");
          }
          self.keditor.save();
        });

        // Image alt text
        _html.find("input.image_alt").on('change', function() {
          if (this.checked) {
            $(this).closest(".image_alt_container").find(".image_alt_input").closest("t-ffield").removeClass('d-none');
          } else {
            $(this).closest(".image_alt_container").find(".image_alt_input").closest("t-ffield").addClass('d-none');
          }
          self.keditor.save();
        });

        // Alt text input
        _html.find("input.image_alt_input").on('input', self.debounce(function() {
          var _val = $(this).val().trim();
          $(el).attr("alt", _val);
          self.keditor.save();
        }, 500));

        // Image open/zoom functionality
        _html.find("input.image_open").on('change', function() {
          if (this.checked) {
            $(el).closest("img-cover").removeAttr("data-disable");
            $(el).closest(".img-helper").removeAttr("data-disable");
          } else {
            $(el).closest("img-cover").attr("data-disable", "true");
            $(el).closest(".img-helper").attr("data-disable", "true");
          }
          self.keditor.save();
        });

        // Image link URL input
        _html.find("input.image_link_input").on('input', self.debounce(function() {
          var _val = $(this).val().trim();
          $(el).parent().find(".stretched-link").attr("href", _val);
          self.keditor.save();
        }, 500));
      }

      var name;


      tag = el.tagName.toLowerCase();
      if (tag == "svg") {
        className = el.className.baseVal.toLowerCase();
      } else {
        className = el.className.toLowerCase();
      }
      innerText = el.innerHTML;




      var isVideo = className.indexOf('pgs-video') == 0;
      var isLink = (tag == "a") || (tag == "button");

      var _hideable_div = "",
        _classChange_div = "",
        _classChange2_div = "",
        _classChange3_div = "",
        _classChange4_div = "",
        _classChange5_div = "",
        _classChange6_div = "",
        _classChange7_div = "",
        _classChange8_div = "",
        _classSelect_div = "",
        _textArea_div = "",
        _slider_div = "",
        _slider_div2 = "",
        _slider_div3 = "",
        _slider_div4 = "",
        _color_div = "",
        _pattern_div = "",
        _autoFit_div = "",
        _imageLink_div = "",
        _imageAlt_div = "",
        _imageOpen_div = "",
        _iconpicker_div = "",
        _custom = "",
        _custom_div = "",
        _custom_div2 = "",
        _custom_div3 = "",
        _custom_div4 = "",
        _custom_div5 = "",
        _custom_div6 = "",
        _custom_div7 = "",
        _custom_div8 = "",
        _html,
        _html2,
        _html3,
        _html4,
        _html5,
        _html6,
        _options = {},
        isDisabled = "",
        _elContent,
        _bgcolor,
        _bgcolorDiv,
        _bgImage,
        _bgImageDiv,
        _textcolor,
        _textcolorDiv,
        _bordercolor,
        _bordercolorDiv,
        _bgimage,
        _bgimageDiv,
        _bgpatternDiv,
        _name2 = "",
        _name3 = "";
        _name4 = "";
        _name5 = "";
        _name6 = "";
        _name7 = "";
        _name8 = "";



        //self.keditor.groupSettings.editorSettings.custom = {};
        //self.keditor.groupSettings.editorSettings.custom.media = "Hide tennet corner=no_tennet-corner-bottom-right|Show tennet corner=tennet-corner-bottom-right";
        //self.keditor.groupSettings.editorSettings.custom.video = "Hide tennet corner=no_tennet-corner-bottom-right|Show tennet corner=tennet-corner-bottom-right";
      // set classchange option
      _custom = $(el).attr('data-wps-custom') || $(el).attr('data-wps-custom2') || $(el).attr('data-wps-custom3') ||"";
      var _customEditorSettings = self.keditor.groupSettings?.editorSettings?.custom || false;
      if(_custom && _customEditorSettings){

        if(_custom==="media" && _customEditorSettings.media){
          var custom_name = $(el).attr('data-wps-edit-custom-name') || "CUSTOM SETTINGS";
          _custom_div = '<label>' +
            '<div class="ams-ftitle">' + custom_name + '</div>' +
          '</label>';

          _custom_div += '<t-ffield><select class="classCustom">';
          var _options = _customEditorSettings.media.class.split('|');
          $.each(_options, function (index, val) {

            _val = val.split('=');
            /* iterate through array or object */
            if ($(el).hasClass(_val[1])) {
              _custom_div += "<option selected value='" + _val[1] + "'>" + _val[0] + "</option>";
            } else {
              _custom_div += "<option value='" + _val[1] + "'>" + _val[0] + "</option>";
            }
          });
          _custom_div += '</select></t-ffield>';
        }

        if(_custom==="video" && _customEditorSettings.video){
          var custom_name = $(el).attr('data-wps-edit-custom-name') || "CUSTOM SETTINGS";
          _custom_div = '<label>' +
            '<div class="ams-ftitle">' + custom_name + '</div>' +
          '</label>';

          _custom_div += '<t-ffield><select class="classCustom">';
          var _options = _customEditorSettings.video.class.split('|');
          $.each(_options, function (index, val) {

            _val = val.split('=');
            /* iterate through array or object */
            if ($(el).hasClass(_val[1])) {
              _custom_div += "<option selected value='" + _val[1] + "'>" + _val[0] + "</option>";
            } else {
              _custom_div += "<option value='" + _val[1] + "'>" + _val[0] + "</option>";
            }
          });
          _custom_div += '</select></t-ffield>';
        }

      }





      //console.log("start", $(el));

      //console.log("tag",tag);

      // set hideable option
      _hideable = $(el).attr('data-wps-edit-hide');



      if (_hideable) {
        var isHidden = $(el).css('display') == 'none',
          isChecked = 'checked="checked"',
          hide_name = "" || $(el).attr('data-wps-edit-name');

        if (_hideable == "true") {
          isHidden = $(el).css('display') == 'none';
        } else if (_hideable == "parent") {
          isHidden = $(el).parent().css('display') == 'none';
        } else {
          isHidden = $(el).closest(_hideable).css('display') == 'none';
        }

        if (isHidden) isChecked = ''




        //if (isHidden) isChecked = 'checked="true";'
        var timestamp = Math.random().toString().slice(2, 11);
        //_hideable_div = '<t-ffield><input ' + isChecked + ' name="hide"  class="embed-auto hide" type="checkbox" id="styled-checkbox-1c_' + timestamp + '" placeholder="" value="hide" spellcheck="false" autocorrect="off"><label for="styled-checkbox-1c_' + timestamp + '">Hide element</label></t-ffield>'
        //_hideable_div = '<label><input '+isChecked+' class="hide" type="checkbox" name="hide" ></label>';
        _hideable_div += '<t-ffield><div class="custom-control custom-switch pt-2"><input ' + isChecked + ' type="checkbox" class="custom-control-input  embed-auto hide"  id="styled-checkbox-1c_' + timestamp + '"><label class="custom-control-label" for="styled-checkbox-1c_' + timestamp + '">Visible</label></div></t-ffield>'

      }
      _textArea_div = $(el).attr("data-embedding-script");
      if(_textArea_div !== undefined){
        _textArea_div = '<t-ffield><textarea rows="15" class="form-control textAreaEmbeddingScript" name="textArea" id="textArea" placeholder="Paste your script here" spellcheck="false" autocorrect="off">'+_textArea_div+'</textarea></t-ffield>';
      }else{
        _textArea_div = '';
      }

      // set classchange option
      _classChange = $(el).attr('data-wps-class-change');

      if (_classChange) {
        _classChange = checkClassChange(_classChange);
        var _options = _classChange.split('|');
        var _val;
        var _attrToCheck = $(el).attr('data-wps-class-change-attr');

        _classChange_div += '<t-ffield><select class="classChange">';
        $.each(_options, function (index, val) {
          var [label, value] = val.split('=');
          var isSelected = false;

          if (_attrToCheck) {
            isSelected = $(el).attr(_attrToCheck) === value;
          } else {
            isSelected = $(el).hasClass(value);
          }

          _classChange_div += `<option ${isSelected ? 'selected' : ''} value="${value}">${label}</option>`;
        });


        var _upgrade = self.keditor.groupSettings?.editorSettings?.upgrade || null;
        if (_upgrade) {
          if (_upgrade["data-wps-class-change"]) {
            $.each(_upgrade["data-wps-class-change"], function (key, val) {
              /* iterate through array or object */
              if ($(el).hasClass(key)) {
                var _options = val.split('|');
                $.each(_options, function (index, val) {
                  if (val == "") return;
                  var _val = val.split('=');
                  /* iterate through array or object */
                  if ($(el).hasClass(_val[1])) {
                    _classChange_div += "<option selected value='" + _val[1] + "'>" + _val[0] + "</option>";
                  } else {
                    _classChange_div += "<option value='" + _val[1] + "'>" + _val[0] + "</option>";
                  }
                });
              }
            });
          }

        }

        _classChange_div += '</select></t-ffield>';
      }


      _classChange2 = $(el).attr('data-wps-class-change2');

      if (_classChange2) {
        _classChange2 = checkClassChange(_classChange2);
        var _options = _classChange2.split('|');
        var _val;



        _classChange2_div += '<t-ffield><select class="classChange2">';
        $.each(_options, function (index, val) {

          _val = val.split('=');
          /* iterate through array or object */
          if ($(el).hasClass(_val[1])) {
            _classChange2_div += "<option selected value='" + _val[1] + "'>" + _val[0] + "</option>";
          } else {
            _classChange2_div += "<option value='" + _val[1] + "'>" + _val[0] + "</option>";
          }
        });

        //var _upgrade = self.keditor.groupSettings?.editorSettings?.upgrade  || null;
        var _upgrade = self.keditor.groupSettings?.editorSettings?.upgrade  || null;
        if (_upgrade) {
          if (_upgrade["data-wps-class-change2"]) {
            $.each(_upgrade["data-wps-class-change2"], function (key, val) {
              /* iterate through array or object */
              if ($(el).hasClass(key)) {
                var _options = val.split('|');
                $.each(_options, function (index, val) {
                  if (val == "") return;
                  var _val = val.split('=');
                  /* iterate through array or object */
                  if ($(el).hasClass(_val[1])) {
                    _classChange2_div += "<option selected value='" + _val[1] + "'>" + _val[0] + "</option>";
                  } else {
                    _classChange2_div += "<option value='" + _val[1] + "'>" + _val[0] + "</option>";
                  }
                });
              }
            });
          }

        }

        _classChange2_div += '</select></t-ffield>';
      }

      _classChange3 = $(el).attr('data-wps-class-change3');

      if (_classChange3) {
        _classChange3 = checkClassChange(_classChange3);
        var _options = _classChange3.split('|');
        var _val;



        _classChange3_div += '<t-ffield><select class="classChange3">';
        $.each(_options, function (index, val) {

          _val = val.split('=');
          /* iterate through array or object */
          if ($(el).hasClass(_val[1])) {
            _classChange3_div += "<option selected value='" + _val[1] + "'>" + _val[0] + "</option>";
          } else {
            _classChange3_div += "<option value='" + _val[1] + "'>" + _val[0] + "</option>";
          }
        });

        var _upgrade = self.keditor.groupSettings?.editorSettings?.upgrade  || null;
        if (_upgrade) {
          if (_upgrade["data-wps-class-change3"]) {
            $.each(_upgrade["data-wps-class-change3"], function (key, val) {
              /* iterate through array or object */
              if ($(el).hasClass(key)) {
                var _options = val.split('|');
                $.each(_options, function (index, val) {
                  if (val == "") return;
                  var _val = val.split('=');
                  /* iterate through array or object */
                  if ($(el).hasClass(_val[1])) {
                    _classChange3_div += "<option selected value='" + _val[1] + "'>" + _val[0] + "</option>";
                  } else {
                    _classChange3_div += "<option value='" + _val[1] + "'>" + _val[0] + "</option>";
                  }
                });
              }
            });
          }

        }

        _classChange3_div += '</select></t-ffield>';
      }
      _classChange4 = $(el).attr('data-wps-class-change4');

      if (_classChange4) {
        _classChange4 = checkClassChange(_classChange4);
        var _options = _classChange4.split('|');
        var _val;

        _classChange4_div += '<t-ffield><select class="classChange4">';
        $.each(_options, function (index, val) {

          _val = val.split('=');
          /* iterate through array or object */
          if ($(el).hasClass(_val[1])) {
            _classChange4_div += "<option selected value='" + _val[1] + "'>" + _val[0] + "</option>";
          } else {
            _classChange4_div += "<option value='" + _val[1] + "'>" + _val[0] + "</option>";
          }
        });

        var _upgrade = self.keditor.groupSettings?.editorSettings?.upgrade  || null;
        if (_upgrade) {
          if (_upgrade["data-wps-class-change4"]) {
            $.each(_upgrade["data-wps-class-change4"], function (key, val) {
              /* iterate through array or object */
              if ($(el).hasClass(key)) {
                var _options = val.split('|');
                $.each(_options, function (index, val) {
                  if (val == "") return;
                  var _val = val.split('=');
                  /* iterate through array or object */
                  if ($(el).hasClass(_val[1])) {
                    _classChange4_div += "<option selected value='" + _val[1] + "'>" + _val[0] + "</option>";
                  } else {
                    _classChange4_div += "<option value='" + _val[1] + "'>" + _val[0] + "</option>";
                  }
                });
              }
            });
          }

        }

        _classChange4_div += '</select></t-ffield>';
      }

      _classChange5 = $(el).attr('data-wps-class-change5');

      if (_classChange5) {
        _classChange5 = checkClassChange(_classChange5);
        var _options = _classChange5.split('|');
        var _val;



        _classChange5_div += '<t-ffield><select class="classChange5">';
        $.each(_options, function (index, val) {

          _val = val.split('=');
          /* iterate through array or object */
          if ($(el).hasClass(_val[1])) {
            _classChange5_div += "<option selected value='" + _val[1] + "'>" + _val[0] + "</option>";
          } else {
            _classChange5_div += "<option value='" + _val[1] + "'>" + _val[0] + "</option>";
          }
        });

        var _upgrade = self.keditor.groupSettings?.editorSettings?.upgrade  || null;
        if (_upgrade) {
          if (_upgrade["data-wps-class-change5"]) {
            $.each(_upgrade["data-wps-class-change5"], function (key, val) {
              /* iterate through array or object */
              if ($(el).hasClass(key)) {
                var _options = val.split('|');
                $.each(_options, function (index, val) {
                  if (val == "") return;
                  var _val = val.split('=');
                  /* iterate through array or object */
                  if ($(el).hasClass(_val[1])) {
                    _classChange5_div += "<option selected value='" + _val[1] + "'>" + _val[0] + "</option>";
                  } else {
                    _classChange5_div += "<option value='" + _val[1] + "'>" + _val[0] + "</option>";
                  }
                });
              }
            });
          }

        }

        _classChange5_div += '</select></t-ffield>';
      }

      _classChange6 = $(el).attr('data-wps-class-change6');

      if (_classChange6) {
        _classChange6 = checkClassChange(_classChange6);
        var _options = _classChange6.split('|');
        var _val;



        _classChange6_div += '<t-ffield><select class="classChange6">';
        $.each(_options, function (index, val) {

          _val = val.split('=');
          /* iterate through array or object */
          if ($(el).hasClass(_val[1])) {
            _classChange6_div += "<option selected value='" + _val[1] + "'>" + _val[0] + "</option>";
          } else {
            _classChange6_div += "<option value='" + _val[1] + "'>" + _val[0] + "</option>";
          }
        });

        var _upgrade = self.keditor.groupSettings?.editorSettings?.upgrade  || null;
        if (_upgrade) {
          if (_upgrade["data-wps-class-change6"]) {
            $.each(_upgrade["data-wps-class-change6"], function (key, val) {
              /* iterate through array or object */
              if ($(el).hasClass(key)) {
                var _options = val.split('|');
                $.each(_options, function (index, val) {
                  if (val == "") return;
                  var _val = val.split('=');
                  /* iterate through array or object */
                  if ($(el).hasClass(_val[1])) {
                    _classChange6_div += "<option selected value='" + _val[1] + "'>" + _val[0] + "</option>";
                  } else {
                    _classChange6_div += "<option value='" + _val[1] + "'>" + _val[0] + "</option>";
                  }
                });
              }
            });
          }

        }

        _classChange6_div += '</select></t-ffield>';
      }

      _classChange7 = $(el).attr('data-wps-class-change7');

      if (_classChange7) {
        _classChange7 = checkClassChange(_classChange7);
        var _options = _classChange7.split('|');
        var _val;



        _classChange7_div += '<t-ffield><select class="classChange7">';
        $.each(_options, function (index, val) {

          _val = val.split('=');
          /* iterate through array or object */
          if ($(el).hasClass(_val[1])) {
            _classChange7_div += "<option selected value='" + _val[1] + "'>" + _val[0] + "</option>";
          } else {
            _classChange7_div += "<option value='" + _val[1] + "'>" + _val[0] + "</option>";
          }
        });

        var _upgrade = self.keditor.groupSettings?.editorSettings?.upgrade  || null;
        if (_upgrade) {
          if (_upgrade["data-wps-class-change7"]) {
            $.each(_upgrade["data-wps-class-change7"], function (key, val) {
              /* iterate through array or object */
              if ($(el).hasClass(key)) {
                var _options = val.split('|');
                $.each(_options, function (index, val) {
                  if (val == "") return;
                  var _val = val.split('=');
                  /* iterate through array or object */
                  if ($(el).hasClass(_val[1])) {
                    _classChange7_div += "<option selected value='" + _val[1] + "'>" + _val[0] + "</option>";
                  } else {
                    _classChange7_div += "<option value='" + _val[1] + "'>" + _val[0] + "</option>";
                  }
                });
              }
            });
          }

        }

        _classChange7_div += '</select></t-ffield>';
      }

      _classChange8 = $(el).attr('data-wps-class-change8');

      if (_classChange8) {
        _classChange8 = checkClassChange(_classChange8);
        var _options = _classChange8.split('|');
        var _val;



        _classChange8_div += '<t-ffield><select class="classChange8">';
        $.each(_options, function (index, val) {

          _val = val.split('=');
          /* iterate through array or object */
          if ($(el).hasClass(_val[1])) {
            _classChange8_div += "<option selected value='" + _val[1] + "'>" + _val[0] + "</option>";
          } else {
            _classChange8_div += "<option value='" + _val[1] + "'>" + _val[0] + "</option>";
          }
        });

        var _upgrade = self.keditor.groupSettings?.editorSettings?.upgrade  || null;
        if (_upgrade) {
          if (_upgrade["data-wps-class-change8"]) {
            $.each(_upgrade["data-wps-class-change8"], function (key, val) {
              /* iterate through array or object */
              if ($(el).hasClass(key)) {
                var _options = val.split('|');
                $.each(_options, function (index, val) {
                  if (val == "") return;
                  var _val = val.split('=');
                  /* iterate through array or object */
                  if ($(el).hasClass(_val[1])) {
                    _classChange8_div += "<option selected value='" + _val[1] + "'>" + _val[0] + "</option>";
                  } else {
                    _classChange8_div += "<option value='" + _val[1] + "'>" + _val[0] + "</option>";
                  }
                });
              }
            });
          }

        }

        _classChange8_div += '</select></t-ffield>';
      }

      // set classselect option
      _classSelect = $(el).attr('data-wps-class-select');

      if (_classSelect) {
        var _options = _classSelect.split('|');
        var _val;



        _classSelect_div += '<t-ffield><select multiple class="classSelect">';
        $.each(_options, function (index, val) {

          _val = val.split('=');
          /* iterate through array or object */
          if ($(el).hasClass(_val[1])) {
            //_classSelect_div+= "<option selected value='"+_val[1]+"'>"+_val[0]+"</option>";
          } else {
            //_classSelect_div+= "<option value='"+_val[1]+"'>"+_val[0]+"</option>";
          }



          _classSelect_div += '<optgroup label="Style">' +
            '<option value="btn-link">Link</option>' +
            '<option value="btn-outline-dark">Outline</option>' +
            '<option selected value="btn-dark">Filled</option>' +
            '</optgroup>' +
            '<optgroup label="Color">' +
            '<option value="bg-white">White background</option>' +
            '<option selected value="bg-dark">Dark background</option>' +
            '<option value="bg-primary">Primary background</option>' +
            '<option value="bg-secondary">Secondary background</option>' +
            '</optgroup>' +
            '<optgroup label="Text Color">' +
            '<option value="text-white">White Text</option>' +
            '<option value="text-dark">Dark Text</option>' +
            '<option selected value="text-primary">Primary Text</option>' +
            '<option value="text-secondary">Secondary Text</option>' +
            '</optgroup>'
        });
        _classSelect_div += '</select></t-ffield>';
      }





      var _slider4 = $(el).attr('data-wps-slider4');
      if (_slider4) {
        var _val,
          _currentAttr = $(el).attr('id') || "",
          _currentClass = $(el).attr('data-wps-edit'),
          _sliderAttr = $(el).attr('data-wps-slider4'),
          _property = _sliderAttr.split(';')[0],
          _min = _sliderAttr.split(';')[1] || "",
          _max = _sliderAttr.split(';')[2] || "",
          _unit = _sliderAttr.split(';')[3] || "";
        _important = _sliderAttr.split(';')[4] || true;

        if (_property.split(';')[0] == "top" ||
          _property.split(';')[0] == "left" ||
          _property.split(';')[0] == "right" ||
          _property.split(';')[0] == "bottom"
        ) {
          _currentProperty = parseInt($(el)[0].style[_property.split(';')[0]]);
        } else {
          _currentProperty = parseInt($(el).css(_property.split(';')[0]));
        }
        if (_unit == "%") {
          _currentProperty = parseInt($(el)[0].style[_property.split(';')[0]]);
        }
        if (_unit == "vh") {
          _currentProperty = parseInt($(el)[0].style[_property.split(';')[0]]);
        }

        if (_currentProperty == undefined) _currentProperty = "";


        _slider_div4 = '<div><label><div class="ams-ftitle">Change ' + _property + '</div></label><t-ffield><button class="slider-minus4 pl-0">-</button><input min="' + _min + '" max="' + _max + '" data-property="' + _property + '" data-important="' + _important + '" data-unit="' + _unit + '" value="' + _currentProperty + '" class="slider4" type="range"><button class="slider-plus4">+</button><span class="slider_counter">' + _currentProperty + _unit + '</span></div></t-ffield>';
      };

      var _slider3 = $(el).attr('data-wps-slider3');
      if (_slider3) {
        var _val,
          _currentAttr = $(el).attr('id') || "",
          _currentClass = $(el).attr('data-wps-edit'),
          _sliderAttr = $(el).attr('data-wps-slider3'),
          _property = _sliderAttr.split(';')[0],
          _min = _sliderAttr.split(';')[1] || "",
          _max = _sliderAttr.split(';')[2] || "",
          _unit = _sliderAttr.split(';')[3] || "";
        _important = _sliderAttr.split(';')[4] || true;

        if (_property.split(';')[0] == "top" ||
          _property.split(';')[0] == "left" ||
          _property.split(';')[0] == "right" ||
          _property.split(';')[0] == "bottom"
        ) {
          _currentProperty = parseInt($(el)[0].style[_property.split(';')[0]]);
        } else {
          _currentProperty = parseInt($(el).css(_property.split(';')[0]));
        }
        if (_unit == "%") {
          _currentProperty = parseInt($(el)[0].style[_property.split(';')[0]]);
        }
        if (_unit == "vh") {
          _currentProperty = parseInt($(el)[0].style[_property.split(';')[0]]);
        }

        if (_currentProperty == undefined) _currentProperty = "";


        _slider_div3 = '<div><label><div class="ams-ftitle">Change ' + _property + '</div></label><t-ffield><button class="slider-minus3 pl-0">-</button><input min="' + _min + '" max="' + _max + '" data-property="' + _property + '" data-important="' + _important + '" data-unit="' + _unit + '" value="' + _currentProperty + '" class="slider3" type="range"><button class="slider-plus3">+</button><span class="slider_counter">' + _currentProperty + _unit + '</span></div></t-ffield>';
      };


      var _slider2 = $(el).attr('data-wps-slider2');

      if (_slider2) {
        var _val,
          _currentAttr = $(el).attr('id') || "",
          _currentClass = $(el).attr('data-wps-edit'),
          _sliderAttr = $(el).attr('data-wps-slider2'),
          _property = _sliderAttr.split(';')[0],
          _min = _sliderAttr.split(';')[1] || "",
          _max = _sliderAttr.split(';')[2] || "",
          _unit = _sliderAttr.split(';')[3] || "";
        _important = _sliderAttr.split(';')[4] || true;
        if (_unit == "") _unit = "px";
        if (_property.split(';')[0] == "top" ||
          _property.split(';')[0] == "left" ||
          _property.split(';')[0] == "right" ||
          _property.split(';')[0] == "bottom"
        ) {
          _currentProperty = parseInt($(el)[0].style[_property.split(';')[0]]);
        } else {
          _currentProperty = parseInt($(el).css(_property.split(';')[0]));
        }



        if (_unit == "%") {
          _currentProperty = parseInt($(el)[0].style[_property.split(';')[0]]);
        }
        if (_unit == "vh") {
          _currentProperty = parseInt($(el)[0].style[_property.split(';')[0]]);
        }
        if (_currentProperty == undefined) _currentProperty = "";


        _slider_div2 = '<div><label><div class="ams-ftitle">Change ' + _property + '</div></label><t-ffield><button class="slider-minus2 pl-0">-</button><input min="' + _min + '" max="' + _max + '" data-property="' + _property + '" data-important="' + _important + '" data-unit="' + _unit + '" value="' + _currentProperty + '" class="slider2" type="range"><button class="slider-plus2">+</button><span class="slider_counter">' + _currentProperty + _unit + '</span></div></t-ffield>';
      };

      var _slider = $(el).attr('data-wps-slider');
      if (_slider) {
        var _val,
          _currentAttr = $(el).attr('id') || "",
          _currentClass = $(el).attr('data-wps-edit'),
          _sliderAttr = $(el).attr('data-wps-slider'),
          _property = _sliderAttr.split(';')[0],
          _min = _sliderAttr.split(';')[1] || "",
          _max = _sliderAttr.split(';')[2] || "",
          _unit = _sliderAttr.split(';')[3] || "";
        _important = _sliderAttr.split(';')[4] || true;
        if (_unit == "") _unit = "px";
        if (_property.split(';')[0] == "top" ||
          _property.split(';')[0] == "left" ||
          _property.split(';')[0] == "right" ||
          _property.split(';')[0] == "bottom"
        ) {
          _currentProperty = parseInt($(el)[0].style[_property.split(';')[0]]);
        } else {
          _currentProperty = parseInt($(el).css(_property.split(';')[0]));
        }


        if (_unit == "%") {
          _currentProperty = parseInt($(el)[0].style[_property.split(';')[0]]);
        }
        if (_unit == "vh") {
          _currentProperty = parseInt($(el)[0].style[_property.split(';')[0]]);
        }


        if (_currentProperty == undefined) _currentProperty = "";


        _slider_div = '<div><label><div class="ams-ftitle">Change ' + _property + '</div></label><t-ffield><button class="slider-minus pl-0">-</button><input min="' + _min + '" max="' + _max + '" data-property="' + _property + '" data-important="' + _important + '" data-unit="' + _unit + '" value="' + _currentProperty + '" class="slider" type="range"><button class="slider-plus">+</button><span class="slider_counter">' + _currentProperty + _unit + '</span></div></t-ffield>';


      };





      //console.error("_classSelect_div",_classSelect_div, _classSelect);

      //Add background pattern
      _bgpattern = $(el).attr('data-wps-bgpattern');

      if (_bgpattern) {
        _bgpatternDiv = '<label><div class="ams-ftitle">Add background pattern</div></label>';

        var patterns = "No pattern=|Dots (left-top)=pattern dots-pattern pattern-left pattern-top| Dots (right-top)=pattern dots-pattern pattern-right pattern-top| Dots (left-bottom)=pattern dots-pattern pattern-left pattern-bottom|Dots (right-bottom)=pattern dots-pattern pattern-right pattern-bottom|Dots Big (left-top)=pattern dots-pattern pattern-left pattern-big pattern-top|Dots Big (right-top)=pattern dots-pattern pattern-right pattern-big pattern-top|Dots Big (left-bottom)=pattern dots-pattern pattern-left pattern-big pattern-bottom|Dots Big (right-bottom)=pattern dots-pattern pattern-right pattern-big pattern-bottom|Stripe (left-top)=pattern stripe-pattern pattern-left pattern-top| Stripe (right-top)=pattern stripe-pattern pattern-right pattern-top|Stripe (left-bottom)=pattern stripe-pattern pattern-left pattern-bottom|Stripe (right-bottom)=pattern stripe-pattern pattern-right pattern-bottom|Stripe Big (left-top)=pattern stripe-pattern pattern-left pattern-big pattern-top|Stripe Big (right-top)=pattern stripe-pattern pattern-right pattern-big pattern-top|Stripe Big (left-bottom)=pattern stripe-pattern pattern-left pattern-big pattern-bottom|Stripe Big (right-bottom)=pattern stripe-pattern pattern-right pattern-big pattern-bottom"
        var _options = patterns.split('|');
        var _val;
        _bgpatternDiv += '<t-ffield><select class="classChange">';
        $.each(_options, function (index, val) {

          _val = val.split('=');
          /* iterate through array or object */
          if ($(el).hasClass(_val[1])) {
            _bgpatternDiv += "<option selected value='" + _val[1] + "'>" + _val[0] + "</option>";
          } else {
            _bgpatternDiv += "<option value='" + _val[1] + "'>" + _val[0] + "</option>";
          }
        });

        //_bgpatternDiv +=  "<input type='text' class='_bgpattern' />";
        _bgpatternDiv += '</select></t-ffield>';

        _pattern_div += _bgpatternDiv;

      }



      function buildColorOptions(el) {
        var colorOptions = {
          html: '',
          bgcolor: false,
          textcolor: false,
          bordercolor: false
        };

        // Background color
        if ($(el).attr('data-wps-bgcolor')) {
          colorOptions.bgcolor = true;
          colorOptions.html += "<label><div class='ams-ftitle'>Custom background color</div></label>" +
                              "<div class='_bgcolor'></div>";
        }

        // Text color
        if ($(el).attr('data-wps-textcolor')) {
          colorOptions.textcolor = true;
          colorOptions.html += "<label><div class='ams-ftitle'>Custom text color</div></label>" +
                              "<div class='_textcolor'></div>";
        }

        // Border color
        if ($(el).attr('data-wps-bordercolor')) {
          colorOptions.bordercolor = true;
          colorOptions.html += "<label><div class='ams-ftitle'>Custom border color</div></label>" +
                              "<div class='_bordercolor'></div>";
        }

        return colorOptions;
      }

        // Replace existing color handling code with function call
      var colorOptions = buildColorOptions(el);

      _color_div = (colorOptions.bgcolor || colorOptions.textcolor || colorOptions.bordercolor) ? colorOptions.html : '';
      console.log("_color_div", _color_div);



      // set auto-fit option
      var _autoFit = $(el).attr('data-wps-auto-fit')

      if (_autoFit) {

        if ($(el).parent().hasClass("no-auto_fit")) isChecked = ''
        if ($(el).parent().hasClass("auto_fit")) {
          isChecked = 'checked="true"';
          isDisabled = 'disabled="true"';
          isDisabled = 'd-none';
        }

        var _el, _pb;
        if (_autoFit == "true") {
          _el = $(el).parent();
        } else {
          _el = $(el).closest(_autoFit);
        }

        _pb = _el.css('padding-bottom');
        _pb = parseFloat(_pb) / parseFloat($(_el).css('width')) * 100;
        // console.log("_pb",_pb)
        _pb = Math.round(_pb * 100) / 100;

        //console.log("_pb",_pb)

        var timestamp = Math.random().toString().slice(2, 11);
        _autoFit_div = '<div class="auto_fit_container">';
        _autoFit_div += '<t-ffield><input ' + isChecked + ' name="hide"  class="embed-auto auto_fit" type="checkbox" id="styled-checkbox-2_' + timestamp + '" placeholder="" value="hide" spellcheck="false" autocorrect="off"><label for="styled-checkbox-2_' + timestamp + '">Auto fit size</label></t-ffield>'
        _autoFit_div += '<t-ffield class="' + isDisabled + '"><label >Image size: </label><input  value="' + _pb + '" class="adjust_padding" type="number"><t-ffield>';
        _autoFit_div += '</div>';

      }




      if (tag == "i" && $(el).parent().hasClass('fa-picker')) {
        //console.error("Start Picker my friend");
        _name = $(el).attr('data-wps-edit-name');
        if(!_name && _name!="") _name = "Text";

        _iconpicker_div = '<button class="iconpicker d-block mt-1 btn btn-dark" data-iconset="fontawesome4" data-icon="' + $(el).attr('data-icon') + '" role="iconpicker"></button>';

      }





      if ((tag == "a" || tag == "h1" || tag == "h2" || tag == "h3" || tag == "h4" || tag == "h5" || tag == "h6" || tag == "p" || tag == "div" || tag == "i" || tag == "span" || tag == "figcaption" || tag == "address" || tag == "blockquote" || tag == "option" || tag == "label" || tag == "img-cover" || tag == "cite" || tag == "iframe" | tag == "small" || tag == "input" || tag == "textarea" || tag == "hr" || tag == "xsource" || tag == "xv  ideo" || tag == "img-cover" || tag == "figure" || tag == "svg" || tag == "td" || tag == "tr") && !isVideo && !isLink) {









        if (typeof innerText != "string") el_change = $(innerText).find(".fr-element")[0]
        else el_change = false;

        if (!el_change) el_change = el;

        //console.log(el_change)
        //console.log(el_change.innerH)

        console.log("el", el)

        _name = $(el).attr('data-wps-edit-name');
        console.log("_name", _name)
        if(!_name && _name!="") _name = "Text";
        _name2 = $(el).attr('data-wps-edit-name2') || "";
        if (_name2) {
          _name2 = '<label>' +
            '<div class="ams-ftitle">' + _name2 + '</div>' +
            '</label>'
        }
        _name3 = $(el).attr('data-wps-edit-name3') || "";
        if (_name3) {
          _name3 = '<label>' +
            '<div class="ams-ftitle">' + _name3 + '</div>' +
            '</label>'
        }
        _name4 = $(el).attr('data-wps-edit-name4') || "";
        if (_name4) {
          _name4 = '<label>' +
            '<div class="ams-ftitle">' + _name4 + '</div>' +
            '</label>'
        }
        _name5 = $(el).attr('data-wps-edit-name5') || "";
        if (_name5) {
          _name5 = '<label>' +
            '<div class="ams-ftitle">' + _name5 + '</div>' +
            '</label>'
        }
        _name6 = $(el).attr('data-wps-edit-name6') || "";
        if (_name6) {
          _name6 = '<label>' +
            '<div class="ams-ftitle">' + _name6 + '</div>' +
            '</label>'
        }
        _name7 = $(el).attr('data-wps-edit-name7') || "";
        if (_name7) {
          _name7 = '<label>' +
            '<div class="ams-ftitle">' + _name7 + '</div>' +
            '</label>'
        }
        _name8 = $(el).attr('data-wps-edit-name8') || "";
        if (_name8) {
          _name8 = '<label>' +
            '<div class="ams-ftitle">' + _name8 + '</div>' +
            '</label>'
        }

        // if(_custom_div!="") _name = "CUSTOM SETTINGS";





        if (($(el).attr('data-wps-edit').indexOf("false") !== -1) || ($(el).attr('data-wps-edit-disable') && !$(el_change).attr("data-wps-edit-attr"))) {
          _html = $(
            '<ams-fragment  class="">' +
            _hideable_div +
            _custom_div +
            _custom_div2 +
            _custom_div3 +
            '<label>' +
            '<div class="ams-ftitle">' + _name + '</div>' +
            '</label>' +
            _textArea_div +
            _iconpicker_div +
            _classChange_div +
            _color_div +
            _slider_div +
            _classSelect_div +
            _pattern_div +
            _name2 +
            _classChange2_div +
            _slider_div2 +
            _name3 +
            _slider_div3 +
            _classChange3_div +
            _name4 +
            _slider_div4 +
            _custom_div4 +
            _classChange4_div +
            _name5 +
            _custom_div5 +
            _classChange5_div +
            _name6 +
            _custom_div6 +
            _classChange6_div +
            _name7 +
            _custom_div7 +
            _classChange7_div +
            _name8 +
            _custom_div8 +
            _classChange8_div +


            '</ams-fragment>'
          );

          //    console.log("--------------")

        } else {


          _attr = $(el_change).attr("data-wps-edit-attr");

          if (_attr) {
            _elContent = $(el_change).attr(_attr).trim();
          } else {
            if(!el_change["data-froala.editor"]) return;
            _elContent = el_change["data-froala.editor"].html.get();
          }

          if(!custom_name) custom_name = _name;

          _html = $(
            '<ams-fragment  class="">' +
            _hideable_div +
            _custom_div +
            _custom_div2 +
            _custom_div3 +
            '<label>' +
            '<div class="ams-ftitle">' + custom_name + '</div>' +
            '</label>' +
            '<div type="text" class="input_froala" >' + _elContent + '</div>' +
            _textArea_div +
            _iconpicker_div +
            _classChange_div +
            _slider_div +
            _name2 +
            _slider_div2 +

            _classChange2_div +
            _name3 +
            _slider_div3 +

            _classChange3_div +
            _name4 +
            _slider_div4 +
            _custom_div4 +
            _classChange4_div +
            _name5 +
            _custom_div5 +
            _classChange5_div +
            _name6 +
            _custom_div6 +
            _classChange6_div +
            _name7 +
            _custom_div7 +
            _classChange7_div +
            _name8 +
            _custom_div8 +
            _classChange8_div +
            _classSelect_div +

            _color_div +
            _pattern_div +

            '</ams-fragment>'
          );




          if ($(el).attr('data-wps-edit2')) {
            _attr = $(el_change).attr("data-wps-edit-attr2");
            if (_attr) {
              _elContent = $(el_change).attr(_attr).trim();
            } else {
              if(!el_change["data-froala.editor"]) return;
              _elContent = el_change["data-froala.editor"].html.get();
            }

            _name = $(el_change).attr("data-wps-edit-name2");


            _html2 = $(
              '<ams-fragment  class="">' +
              '<label>' +
              '<div class="ams-ftitle">' + _name + '</div>' +
              '</label>' +
              '<div type="text" class="input_froala2" >' + _elContent + '</div>' +
              '</ams-fragment>'
            );

            var _textarea = _html2.find('.input_froala2');

            self.addFroalaEditor(el, _textarea[0]);


          }

          if ($(el).attr('data-wps-edit3')) {
            _attr = $(el_change).attr("data-wps-edit-attr3");
            if (_attr) {
              _elContent = $(el_change).attr(_attr).trim();
            } else {
              if(!el_change["data-froala.editor"]) return;
              _elContent = el_change["data-froala.editor"].html.get();
            }
            _name = $(el_change).attr("data-wps-edit-name3");
            _html3 = $(
              '<ams-fragment  class="">' +
              '<label>' +
              '<div class="ams-ftitle">' + _name + '</div>' +
              '</label>' +
              '<div type="text" class="input_froala3" >' + _elContent + '</div>' +
              '</ams-fragment>'
            );
            var _textarea = _html3.find('.input_froala3');
            self.addFroalaEditor(el, _textarea[0]);
          }
          if ($(el).attr('data-wps-edit4')) {
            _attr = $(el_change).attr("data-wps-edit-attr4");
            if (_attr) {
              _elContent = $(el_change).attr(_attr).trim();
            } else {
              if(!el_change["data-froala.editor"]) return;
              _elContent = el_change["data-froala.editor"].html.get();
            }
            _name = $(el_change).attr("data-wps-edit-name4");
            _html4 = $(
              '<ams-fragment  class="">' +
              '<label>' +
              '<div class="ams-ftitle">' + _name + '</div>' +
              '</label>' +
              '<div type="text" class="input_froala4" >' + _elContent + '</div>' +
              '</ams-fragment>'
            );
            var _textarea = _html4.find('.input_froala4');
            self.addFroalaEditor(el, _textarea[0]);
          }
          if ($(el).attr('data-wps-edit5')) {
            _attr = $(el_change).attr("data-wps-edit-attr5");
            if (_attr) {
              _elContent = $(el_change).attr(_attr).trim();
            } else {
              if(!el_change["data-froala.editor"]) return;
              _elContent = el_change["data-froala.editor"].html.get();
            }
            _name = $(el_change).attr("data-wps-edit-name5");
            _html5 = $(
              '<ams-fragment  class="">' +
              '<label>' +
              '<div class="ams-ftitle">' + _name + '</div>' +
              '</label>' +
              '<div type="text" class="input_froala5" >' + _elContent + '</div>' +
              '</ams-fragment>'
            );
            var _textarea = _html5.find('.input_froala5');
            self.addFroalaEditor(el, _textarea[0]);
          }
          if ($(el).attr('data-wps-edit6')) {
            _attr = $(el_change).attr("data-wps-edit-attr6");
            if (_attr) {
              _elContent = $(el_change).attr(_attr).trim();
            } else {
              if(!el_change["data-froala.editor"]) return;
              _elContent = el_change["data-froala.editor"].html.get();
            }
            _name = $(el_change).attr("data-wps-edit-name6");
            _html6 = $(
              '<ams-fragment  class="">' +
              '<label>' +
              '<div class="ams-ftitle">' + _name + '</div>' +
              '</label>' +
              '<div type="text" class="input_froala6" >' + _elContent + '</div>' +
              '</ams-fragment>'
            );
            var _textarea = _html6.find('.input_froala6');
            self.addFroalaEditor(el, _textarea[0]);
          }



          var _textarea = _html.find('.input_froala');
          self.addFroalaEditor(el, _textarea[0])
        }


        form.append(_html);
        form.append(_html2);
        form.append(_html3);
        form.append(_html4);
        form.append(_html5);
        form.append(_html6);

        //console.error("_html",_html)
      } else {
        console.error("Tag not supported", tag)
      }









      if (tag == "img") {

        _name = $(el).attr('data-wps-edit-name');
        if(!_name && _name!="") _name = "Image";

        // add image link div
        var timestamp = Math.random().toString().slice(2, 11);
        var isChecked = "",
            isCheckedAlt = "",
            isDisabled = 'd-none',
            isDisabledAlt = 'd-none',
            _href = "",
            _alt = "";
        if ($(el).closest(".link_image").length > 0) {
          isChecked = 'checked="true"';
          isDisabled = '';
          _href = $(el).parent().find("a.stretched-link").attr("href");
        }
        _imageLink_div = '<div class="image_link_container">';
        _imageLink_div += '<t-ffield><input ' + isChecked + ' name="hide"  class="image_link" type="checkbox" id="styled-checkbox-3_' + timestamp + '" placeholder="" value="hide" spellcheck="false" autocorrect="off"><label for="styled-checkbox-3_' + timestamp + '">Add image Link</label></t-ffield>'
        _imageLink_div += '<t-ffield class="ml-2 ' + isDisabled + '"><label >Link URL: </label><input value="' + _href + '" class="image_link_input" type="text"></t-ffield>';
        if ($(el).attr("target")=="_blank" ) {
          isChecked = 'checked="true"';
        }
        _imageLink_div += '<t-ffield class="ml-2 ' + isDisabled + '"><input ' + isChecked + ' name="hide"  class="image_target " type="checkbox" id="styled-checkbox-3b_' + timestamp + '" placeholder="" value="hide" spellcheck="false" autocorrect="off"><label for="styled-checkbox-3b_' + timestamp + '">Open in new Tab</label></t-ffield>'
        _imageLink_div += '</div>';

        // if ($(el).attr("target")=="_blank" ) {
        //   isChecked = 'checked="true"';
        //   isDisabled = '';
        // }
        // _imageTarget_div = '<div class="image_target_container">';
        // _imageTarget_div += '<t-ffield><input ' + isChecked + ' name="hide"  class="image_target" type="checkbox" id="styled-checkbox-3b_' + timestamp + '" placeholder="" value="hide" spellcheck="false" autocorrect="off"><label for="styled-checkbox-3b_' + timestamp + '">Add image Link</label></t-ffield>'
        // _imageTarget_div += '<t-ffield class="' + isDisabled + '"><label >ALT Text: </label><input value="' + _href + '" class="image_target_input" type="text"><t-ffield>';
        // _imageTarget_div += '</div>';



        if ($(el).attr("alt")){
          isCheckedAlt = 'checked="true"';
          isDisabledAlt = '';
        }
        _imageAlt_div = '<div class="image_alt_container">';
        _imageAlt_div += '<t-ffield><input ' + isCheckedAlt + ' name="hide"  class="image_alt" type="checkbox" id="styled-checkbox-3a_' + timestamp + '" placeholder="" value="hide" spellcheck="false" autocorrect="off"><label for="styled-checkbox-3a_' + timestamp + '">Add alt text</label></t-ffield>'
        _imageAlt_div += '<t-ffield class="' + isDisabledAlt + '"><label >ALT text: </label><input value="' + _alt + '" class="image_alt_input" type="text"><t-ffield>';
        _imageAlt_div += '</div>';





        isChecked = 'checked="true"';
        if ($(el).closest("img-cover[data-disable='true']").length > 0 || $(el).closest(".img-helper[data-disable='true']").length > 0) {
          isChecked = "";
        }
        if($(el).closest("img-cover").length>0) _imageOpen_div += '<t-ffield><input ' + isChecked + ' name="hide"  class="image_open" type="checkbox" id="styled-checkbox-4_' + timestamp + '" placeholder="" value="hide" spellcheck="false" autocorrect="off"><label for="styled-checkbox-4_' + timestamp + '">Zoom image on click</label></t-ffield>'


        _html = $(
          '<ams-fragment  class="ams-fragment-media ams-upload ams-populated ams-layout-mixed">' +
          '<div class="ams-ftitle">' + _name + '</div>' +
          _hideable_div +
          '<label>' +
          '<div class="ams-ftitle">' + _name + '</div>' +
          '<div class="ams-ffield">' +
          '<div class="ams-fbox">' +
          '<div class="ams-preload"><div class="ams-preloader"></div></div>' +
          '<div class="ams-addimage"></div>' +
          '<div class="ams-focalpoint" title="Set focalpoint"></div>' +
          //'<div class="ams-addfocalpoint" title="Set focalpoint"></div>'+
          '<div class="ams-image">' +
          '<img src="' + el.src + '">' +
          '</div>' +
          //'<div class="ams-cross"></div>' +
          '</div>' +
          '</div>' +
          '</label>' +
          _autoFit_div +
          _imageLink_div +
          _imageAlt_div +
          _imageOpen_div +
          _slider_div +

          '</ams-fragment>'
        );

        /*var inputText = _html.find('input');
        inputText.val(innerText);
        inputText.on('input', function () {
            el.innerHTML = $(this).val();
        });*/

        form.append(_html)


        var dropzone = _html.find(".ams-fbox"),
          settingsImage = _html.find(".ams-image img"),
          image = el;


        $(settingsImage).off("click").on("click", function (ev) {


          var _img = $(image),
            _size = $(_img).parent().attr('data-size'),
            _focus = $(_img).parent().attr('data-focus'),
            _settingsImage = ev.currentTarget;
            if(_size){
              _size = _size.split(",")
            }

            if(!_size) _size = false;

          if (typeof self.keditor.options.callFromKeditor === 'function') self.keditor.options.callFromKeditor("imageUploadModal", {
            "src": image.src,
            "width": _size[0] || $(window).width(),
            "height": _size[1] || $(window).height(),
            "focal": _focus
          }, function (data) {
            // set image dimensions
            var _w = _size[0] || $(window).width();
            var _h = _size[1] || $(window).height();

            data[0].url = self.keditor.cloudImageFunction(data[0].url, { width: _w, height: _h }, data[0]);


            var _group = $(_img).attr("data-wps-edit-group");
            var _focal = data[0].focalpoint;


            _settingsImage.src = data[0].url;
            if(data[0].personalization){
              _img.attr("data-wps-variable", data[0].personalization.setId+"."+data[0].personalization.valueId)
            }else{
              _img.removeAttr("data-wps-variable");
            }

            // preload the result image thumbnail


            $(_img).removeAttr('data-wps-edit-variable'); //delete data-wps-edit-variable
            $(_img).removeAttr('srcset'); //delete data-wps-edit-variable
            $(_img).attr("data-url", data[0].url);
            $(_img).parent().attr("data-focus", _focal.x + "," + _focal.y);
            if (data[0].width && data[0].height) $(_img).parent().attr("data-size", data[0].width + "," + data[0].height);
            $(_img).attr("src", data[0].url)

            if (data[0].width && data[0].height) {
              if (!$(_img).parent().hasClass("no_auto_fit")) {
                var _el,
                  _auto_fit = $(_img).attr('data-wps-auto-fit');
                if (_auto_fit == true) {
                  _el = $(_img).parent();
                } else {
                  _el = $(_img).closest(_auto_fit);
                }
                var _percentage = data[0].height / data[0].width * 100;
                _el.css({
                  paddingBottom: _percentage + '%'
                });
              }
            }

            if (_group) {
              // find group attributes in component
              var _groupEl = component.find('[data-wps-edit-group="' + _group + '"]');

              $.each(_groupEl, function (index, el) {

                // if it is not the _img itself, change image to new one
                if (el != image[0]) {
                  $(el).attr("data-srcset", _srcset);
                  //$(el).attr("data-id", data.file.id);
                  $(el).parent().attr("data-focus", "50,50");

                  // if attribute data-wps-edit-size was set in element:
                  if (_size) {
                    $(el).attr("src", data[0].url.replace("/s/", "/" + _size + "/"));
                  } else {
                    $(el).attr("src", data[0].url.replace("/s/", "/l/"));
                  }
                }
              });
              // find group attributes in settings-form
              var container = _button.closest(".keditor-setting-form");
              var _groupEl = container.find('[data-wps-edit-group="' + _group + '"]');
              $.each(_groupEl, function (index, el) {
                $(el).attr("src", img.src);
              });
            }

            // cloudimage.io
            // first set src to si-src to make it work
            var ciResponsive = new window.parent.CIResponsive({
              token: 'axyqwmwryo'
            });

            self.keditor.window.imgCover.update(self.keditor.body[0]);
            self.keditor.save();


          })
        })

        // Call the new setup function
        setupImageOptions(el, _html);
      }

      if (tag == "video") {
        _name = $(el).attr('data-wps-edit-name');
        if(!_name && _name!="") _name = "Video";
        _name2 = "MP4 Video file (max 10MB)",
          _source = $(el).find("source").attr("data-src") || $(el).find("source").attr("src") || "";

        _html = $(
          '<div class="webmag_video_container">' +
          '<ams-fragment>' +
          '<div  class="ams-fragment-media ams-upload ams-populated ams-layout-mixed">' +
          '<div class="ams-ftitle">' + _name + '</div>' +
          _hideable_div +
          '<label>' +
          '<div class="ams-ftitle">' + _name + '</div>' +
          '<div class="ams-ffield">' +
          '<div class="ams-fbox">' +
          '<div class="ams-preload"><div class="ams-preloader"></div></div>' +
          '<div class="ams-addimage"></div>' +
          '<div class="ams-image">' +
          '<img src="' + $(el).attr("poster") + '">' +
          '</div>' +
          //'<div class="ams-cross"></div>' +
          '</div>' +
          '</div>' +
          '</label>' +
          '</div>' +
          '<div  class="ams-fragment-media ams-upload ams-populated ams-layout-mixed">' +
          '<label>' +
          '<div class="ams-ftitle">' + _name2 + '</div>' +
          '<div class="ams-ffield">' +
          '<div class="ams-fbox">' +
          '<div class="ams-preload"><div class="ams-preloader"></div></div>' +
          '<div class="ams-addimage"></div>' +
          '<div class="ams-image">' +
          '<img src="https://cdn.webmag.io/webmag/thumb_mp4_u.jpg">' +
          '</div>' +
          //'<div class="ams-cross"></div>' +
          '</div>' +
          '</div>' +
          '</label>' +
          '<t-ffield>' +
          '<label>' +
          '<div class="ams-ftitle">MP4 video file URL</div>' +
          '</label>' +
          '</t-ffield>' +
          '<input type="text" value="' + _source + '" class="mp4_url input_froala fr-box fr-ltr fr-inline" ></input>' +
          '</div>' +
          '</ams-fragment>' +
          '</div>'
        );

        form.append(_html)

        var dropzone = _html.find(".ams-fbox").eq(0),
          video = el;
        $(dropzone).on("click", function (ev) {
          _el = $(this).find("img");
          if (typeof self.keditor.options.callFromKeditor === 'function') self.keditor.options.callFromKeditor("imageUploadModal", {
            "src": _el[0].src,
          }, function (data) {
            $(video).attr("poster", data[0].url);
            $(_el).attr("src", data[0].url);
            self.keditor.save();
          })
        })


        var dropzone = _html.find(".ams-fbox").eq(1),
          video2 = $(el).find("source");

        $(dropzone).on("click", function (ev) {
          var _el = $(this).find("img");
          if (typeof self.keditor.options.callFromKeditor === 'function') self.keditor.options.callFromKeditor("imageUploadModal", {
            "src": _el[0].src,
            "filetype": "video/mp4",
            "focal": false
          }, function (data) {
            $(video2).attr("data-src", data[0].src);
            _html.closest(".webmag_video_container").find(".mp4_url").attr("value", data[0].src);
            self.keditor.save();
          })
        })

        _html.closest(".webmag_video_container").find(".mp4_url").on('input', self.debounce(function () {
          var _val = $(this).val().trim();
          $(video2).attr("data-src", _val);
          self.keditor.save();
        }, 500));
      }

      if (tag == "audio") {
        _name = $(el).attr('data-wps-edit-name');
        if(!_name && _name!="") _name = "Audio";
        _name2 = "MP3 Audio file (max 10MB)";

        _html = $(
          '<div class="webmag_audio_container">' +
          '<ams-fragment  class="ams-fragment-media ams-upload ams-populated ams-layout-mixed">' +
          '<label>' +
          '<div class="ams-ftitle">' + _name2 + '</div>' +
          '<div class="ams-ffield">' +
          '<div class="ams-fbox">' +
          '<div class="ams-preload"><div class="ams-preloader"></div></div>' +
          '<div class="ams-addimage"></div>' +
          '<div class="ams-image">' +
          '<img src="https://cdn.webmag.io/webmag/thumb_mp3_u.jpg">' +
          '</div>' +
          //'<div class="ams-cross"></div>' +
          '</div>' +
          '</div>' +
          '</label>' +
          '</ams-fragment>' +
          '<ams-fragment  class="">' +
          '<label>' +
          '<div class="ams-ftitle">MP3 audio file URL</div>' +
          '</label>' +
          '<input type="text" value="' + $(el).find("source").attr("src") + '" class="mp3_url input_froala fr-box fr-ltr fr-inline" ></input>' +
          '</ams-fragment>' +
          '</div>'
        );

        form.append(_html)


        var dropzone = _html.find(".ams-fbox"),
          audio = $(el).find("source");
        $(dropzone).on("click", function (ev) {
          var _el = $(this).find("img");
          if (typeof self.keditor.options.callFromKeditor === 'function') self.keditor.options.callFromKeditor("imageUploadModal", {
            "src": audio[0].src,
            "filetype": "audio/mp3,audio/mpeg",
            "focal": false
          }, function (data) {
            $(audio).attr("src", data[0].src);
            _html.closest(".webmag_audio_container").find(".mp3_url").attr("value", data[0].src);
            self.keditor.save();
          })
        })


        _html.closest(".webmag_audio_container").find(".mp3_url").on('input', self.debounce(function () {
          var _val = $(this).val().trim();
          $(audio).attr("src", _val);
          self.keditor.save();
        }, 500));
      }



      if (isLink) {
        _name = $(el).attr('data-wps-edit-name');
        if(!_name && _name!="") _name = "Text";
        innerText = $(el).attr('href');



        //if($(el).attr('data-wps-edit-widget')=="article_links"){

        //  var _modules = $.grep(window.parent._AMS.project.modules.content, function(e){ return e.menu; });
        //console.error("FIX FOR V2 - modules list", self.keditor.options.allPageSiblings)
        var _modules = self.keditor.options.allPageSiblings;


        var _val = $(el).attr('data-wps-edit-variable'),
          _name = $(el).attr('data-wps-edit-name'),
          myID = "";
          if(!_name && _name!="") _name = "Text";



        if (_val) {
          myID = _val.substring(
            _val.indexOf("'") + 1,
            _val.lastIndexOf("'")
          );
        }

        //console.error("IUDS",myID);
        var _classChange_article_div = '<t-ffield><select class="article_linksChange"><option value="">None</option>';

        /*if(_val && _val.indexOf("ams-nextArticle")>=0){
           _classChange_article_div+= "<option selected value='next_article'>Next Article</option>";
        }else{
           _classChange_article_div+= "<option value='next_article'>Next Article</option>";
        }*/


        /*if(_val && _val.indexOf("ams-prevArticle")>=0){
           _classChange_article_div+= "<option selected value='previous_article'>Previous Article</option>";
        }else{
           _classChange_article_div+= "<option value='previous_article'>Previous Article</option>";
        }*/



        $.each(_modules, function (index, val) {

          if (val.slug == innerText) {
            _classChange_article_div += "<option selected value='" + val.pageId + "'>" + val.title + "</option>";
          } else {
            _classChange_article_div += "<option value='" + val.pageId + "'>" + val.title + "</option>";
          }


          // _val = val.split('=');
          /* iterate through array or object */
          /*if($(el).hasClass(_val[1])){
            _classChange_article_div+= "<option selected value='"+_val[1]+"'>"+_val[0]+"</option>";
          }else{
            _classChange_article_div+= "<option value='"+_val[1]+"'>"+_val[0]+"</option>";
          }*/
        });
        _classChange_article_div += '</select></t-ffield>';




        // form.append(_html)

        //}


        var _linkText = "";


        var _hasText = true;
        if (!$(el).attr('data-wps-edit-disable')) {
          $(el).text().trim().length;
        } else {
          _hasText = false;
        }

        if (_hasText) {
          _linkText = '<label>' +
            '<div class="ams-ftitle">Text</div>' +
            '</label>' +
            '<div  class=" ams_link_textarea_text" >' + $(el).text() + '</div>';
        }


        var _classChange = "";



        if ((!$(el).attr('data-wps-options') || !$(el).attr('data-wps-options') == "linkEdit=false")) {
          if (!$(el).attr('data-fancybox')) {
            _classChange = '<label>' +
              '<div class="ams-ftitle">' + "Add internal link" + '</div>' +
              '</label>' +
              _classChange_article_div
          }

        } else {

        }


        if (_classChange_div) {
          _classChange_div = '<label>' +
            '<div class="ams-ftitle">Element settings</div>' +
            '</label>' +
            _classChange_div;
        }

        if (_classChange2_div) {
          _classChange2_div = '<label>' +
            '<div class="ams-ftitle">Element settings</div>' +
            '</label>' +
            _classChange2_div;
        }

        if (_classChange3_div) {
          _classChange3_div = '<label>' +
            '<div class="ams-ftitle">Element settings</div>' +
            '</label>' +
            _classChange3_div;
        }
        if (_classChange4_div) {
          _classChange4_div = '<label>' +
            '<div class="ams-ftitle">Element settings</div>' +
            '</label>' +
            _classChange4_div;
        }
        if (_classChange5_div) {
          _classChange5_div = '<label>' +
            '<div class="ams-ftitle">Element settings</div>' +
            '</label>' +
            _classChange5_div;
        }
        if (_classChange6_div) {
          _classChange6_div = '<label>' +
            '<div class="ams-ftitle">Element settings</div>' +
            '</label>' +
            _classChange6_div;
        }
        if (_classChange7_div) {
          _classChange7_div = '<label>' +
            '<div class="ams-ftitle">Element settings</div>' +
            '</label>' +
            _classChange7_div;
        }
        if (_classChange8_div) {
          _classChange8_div = '<label>' +
            '<div class="ams-ftitle">Element settings</div>' +
            '</label>' +
            _classChange8_div;
        }

        if (_classSelect_div) {
          _classSelect_div = '<label>' +
            '<div class="ams-ftitle">Element settings</div>' +
            '</label>' +
            _classSelect_div;
        }



        var _classButton = $(el).attr('data-wps-class-button'),
          _classButton_div = "";

        if (_classButton) {

          _classButton_div = '<label><div class="ams-ftitle">Button style</div></label>'
          _classButton_div += '<t-ffield><select class="classButton">';

          _classButton_div += '</select></t-ffield>';
        }



        var isChecked = "";
        var timestamp = Math.random().toString().slice(2, 11);
        if ($(el).attr('target') == '_blank') isChecked = 'checked="true"';
        _linkBlank_div = '<t-ffield><input ' + isChecked + ' name="linkblank"  class="linkblank" type="checkbox" id="styled-checkbox-1_' + timestamp + '" placeholder=""  spellcheck="false" autocorrect="off"><label for="styled-checkbox-1_' + timestamp + '">Open in new tab</label></t-ffield>'
        if (($(el).attr('data-wps-options') || $(el).attr('data-wps-options') == "linkEdit=false")) _linkBlank_div = "";
        if ((!$(el).attr('data-wps-options') || !$(el).attr('data-wps-options') == "linkEdit=false")) {
          var isChecked2 = "";
          var isChecked3 = 'checked="true"';
          var _display = "d-none";
          var timestamp = Math.random().toString().slice(2, 11);
          if ($(el).attr('data-open-modal') == 'true') {
            isChecked2 = 'checked="true"';
            _display = "d-block";
          }
          if ($(el).attr('data-open-modal-fullscreen') == 'true') {
            isChecked3 = 'checked="true"';
          } else {
            isChecked3 = '';
          }
          _linkModal_div = '<t-ffield><input ' + isChecked2 + ' name="linkModal"  class="linkModal" type="checkbox" id="styled-checkbox-1a_' + timestamp + '" placeholder=""  spellcheck="false" autocorrect="off"><label for="styled-checkbox-1a_' + timestamp + '">Open internal link in modal window</label></t-ffield>'
          //_linkModal_div +=  '<t-ffield class="ml-4 '+_display+'"><input '+isChecked3+' name="linkModal2"  class="linkModal2" type="checkbox" id="styled-checkbox-1b_'+timestamp+'" placeholder=""  spellcheck="false" autocorrect="off"><label for="styled-checkbox-1b_'+timestamp+'">Fullscreen modal window</label></t-ffield>'
        } else {
          _linkModal_div = "";
        }

        _html = $(
          '<ams-fragment  class="">' +
          '<label>' +
          '<div class="ams-ftitle">' + _name + '</div>' +
          '</label>' +
          _hideable_div +

          '<div class="ams_link_textarea_href" >' + innerText + '</div>' +
          _linkBlank_div +
          _classChange +
          _linkModal_div +


          _classChange_div +
          _classChange2_div +
          _classChange3_div +
          _classChange4_div +
          _classChange5_div +
          _classChange6_div +
          _classChange7_div +
          _classChange8_div +
          _classButton_div +
          _classSelect_div +
          _slider_div +
          _color_div +
          _pattern_div +
          //'<div type="text" class="input_froala" >'+innerText+'</div>' +
          _linkText +


          '</ams-fragment>'
        );

        var _edit_options = $(el).attr('data-wps-options')
        if (_edit_options && _edit_options.indexOf("linkEdit=false") >= 0) {
          _html.find('.ams_link_textarea_href').remove();
        } else {
          var _textarea = _html.find('.ams_link_textarea_href');

          self.addFroalaEditor(el, _textarea[0], function contentChanged(val) {
            $(el).removeAttr('data-wps-edit-variable');
            $(el).attr('href', val);
          }, true)
        }

        //hide button input field on start if internal link is null
        /*var _article_linksChangeVal = _html.find("select.article_linksChange").val();
        console.log("_article_linksChangeVal", _article_linksChangeVal)
        console.log(_html.find(".ams_link_textarea_href"))
        if(_article_linksChangeVal!=""){
          _html.find(".ams_link_textarea_href").hide();
        }*/



        if (_hasText) {
          _textarea = _html.find('.ams_link_textarea_text');
          self.addFroalaEditor(el, _textarea[0])
        }


        form.append(_html)


      }

      if (isVideo) {
        _name = $(el).attr('data-wps-edit-name') || "Text";
        innerText = $(el).attr('link-src');


        //set to default
        $(el).find("button").show();
        $(el).find("iframe.pgs-video").remove();

        _html = $(
          '<ams-fragment  class="">' +
          '<label>' +
          '<div class="ams-ftitle">' + _name + '</div>' +
          '</label>' +
          _hideable_div +
          '<div class="ams_link_textarea_src" >' + innerText + '</div>' +
          _slider_div +
          '</ams-fragment>'
        );

        var _textarea = _html.find('.ams_link_textarea_src')


        self.addFroalaEditor(el, _textarea[0], function (val) {


          var srcUrl = val;

          var youRegex = /^http[s]?:\/\/(((www.youtube.com\/watch\?(feature=player_detailpage&)?)v=)|(youtu.be\/))([^#\&\?]*)/;
          var vimeoRegex = /^https?:\/\/(www\.)?vimeo.com\/(channels\/[a-zA-Z0-9]*\/)?(?<id>[0-9]*)(\/(?<hash>[a-zA-Z0-9]+))?(\#t=(\d+)s)?/gm
          var youRegexMatches = youRegex.exec(srcUrl);
          var vimeoRegexMatches = vimeoRegex.exec(srcUrl);






          if (youRegexMatches != null || vimeoRegexMatches != null) {
            if (youRegexMatches != null && youRegexMatches.length >= 7) {
              var youMatch = youRegexMatches[6];
              srcUrl = 'https://www.youtube.com/embed/' + youMatch + '?rel=0';
              //var video_thumbnail = $('<img src="//img.youtube.com/vi/'+youMatch+'/0.jpg">');
              $(el).css("background-image", ' url("https://img.youtube.com/vi/' + youMatch + '/hqdefault.jpg")')
            }
            if (vimeoRegexMatches != null && vimeoRegexMatches.length >= 7) {
              var vimeoMatch = vimeoRegexMatches.groups.id;
              var _param = "";
              if(vimeoRegexMatches.groups.hash){
                _param = "?h="+vimeoRegexMatches.groups.hash
              }
              //var _param = srcUrl.substring(srcUrl.indexOf(vimeoMatch) + vimeoMatch.length);
              srcUrl = 'https://player.vimeo.com/video/' + vimeoMatch+_param;

              $.ajax({
                type: 'GET',
                url: 'https://vimeo.com/api/v2/video/' + vimeoMatch + '.json',
                success: function (data) {
                  var thumbnail_src = data[0].thumbnail_large;
                  $(el).css("background-image", ' url("' + thumbnail_src + '")')
                }
              });
            }
          }





          $(el).attr('link-src', srcUrl);
          $(el).attr('embed-src', srcUrl);


          self.keditor.save();

        }, true)


        form.append(_html)
      }




      if (!_html) {
        console.error("A tag is not supported!", tag)
        return;
      }




      if (colorOptions.bgcolor) {




        _html.find("._bgcolor").data("ref", el);


        var _default = ($(el)[0].style.background) ? $(el)[0].style.background.replace(/!important/g, '').trim() : $(el)[0].style.backgroundColor.replace(/!important/g, '').trim();

        var _prevcolors = ['rgba(0,0,0,0)'];
        _prevcolors = _prevcolors.concat(self.keditor.options.keditorOptions.colorsText);
        //_prevcolors = _prevcolors.concat(["#000", "#444", "#666", "#999", "#ccc", "#eee", "#f3f3f3", "#fff"]);



        //_default = "linear-gradient(0.0deg,hsla(279,63%,58%,1) 0.0,hsla(198,83%,53%,1) 100.0%)";
        var xncolorpicker = new self.keditor.window.XNColorPicker({
          color: _default,
          selector: _html.find("._bgcolor"),
          showprecolor: true,
          prevcolors: _prevcolors,
          showhistorycolor: true,
          historycolornum: 16,
          format: 'hex',
          showPalette: true,
          show: false,
          lang: 'en',
          //colorTypeOption: 'single',
          colorTypeOption:'single,linear-gradient,radial-gradient',
          canMove: true,
          alwaysShow: false,
          autoConfirm: false,
          onError: function (e) {

          },
          onCancel: function (color) {
            console.log("cancel", color)
          },
          onChange: function (color) {
            console.log("change", color)
          },
          onConfirm: function (color) {
            console.log("confirm", color);
            var _color;
            if (color) {
              if (color.colorType == "linear-gradient" || color.colorType == "radial-gradient") {
                _color = color.color.str;
                el.style.setProperty("background", "");
                el.style.setProperty("background-color", "");
                el.style.setProperty("background", "");
                el.style.setProperty("background", _color, "important");

              } else {
                if(color.color.hex === '#00000000'){
                  el.style.setProperty("background", "");
                  el.style.setProperty("background-color", "");
                } else {
                  _color = color.color.rgba;
                  el.style.setProperty("background", "");
                  el.style.setProperty("background-color", "");
                  el.style.setProperty("background-color", _color, "important");
                }
              }
            } else {
              //el.style.setProperty("background-color", "");
              el.style.setProperty("background", "");
              el.style.setProperty("background-color", "");
            }
            self.keditor.save();
          }
        })
      }

      if (colorOptions.textcolor) {




        _html.find("._textcolor").data("ref", el);

        var _default = $(el)[0].style.color.replace(/!important/g, '').trim();

        var _prevcolors = ['rgba(0,0,0,0)'];
        _prevcolors = _prevcolors.concat(self.keditor.options.keditorOptions.colorsText);

        //_default = "linear-gradient(0.0deg,hsla(279,63%,58%,1) 0.0,hsla(198,83%,53%,1) 100.0%)";
        var xncolorpicker = new self.keditor.window.XNColorPicker({
          color: _default,
          selector: _html.find("._textcolor"),
          showprecolor: true,
          prevcolors: _prevcolors,
          showhistorycolor: true,
          historycolornum: 16,
          format: 'hex',
          showPalette: true,
          show: false,
          lang: 'en',
          //colorTypeOption: 'single',
          colorTypeOption:'single',
          canMove: true,
          alwaysShow: false,
          autoConfirm: false,
          onError: function (e) {

          },
          onCancel: function (color) {
            console.log("cancel", color)
          },
          onChange: function (color) {
            console.log("change", color)
          },
          onConfirm: function (color) {

            console.log("confirm", color);
            var _color;
            if (color) {
                if(color.color.hex === '#00000000'){
                  el.style.setProperty("color", "");
                } else {
                  _color = color.color.rgba;
                  el.style.setProperty("color", _color, "important");
                }
            } else {
              //el.style.setProperty("background-color", "");
              el.style.setProperty("color", "");
            }
            self.keditor.save();
          }
        })
      }

      if (colorOptions.bordercolor) {



        _html.find("._bordercolor").data("ref", el);

        var _default = $(el)[0].style.borderColor.replace(/!important/g, '').trim();

        var _prevcolors = ['rgba(0,0,0,0)'];
        _prevcolors = _prevcolors.concat(self.keditor.options.keditorOptions.colorsText);

        //_default = "linear-gradient(0.0deg,hsla(279,63%,58%,1) 0.0,hsla(198,83%,53%,1) 100.0%)";
        var xncolorpicker = new self.keditor.window.XNColorPicker({
          color: _default,
          selector: _html.find("._bordercolor"),
          showprecolor: true,
          prevcolors: _prevcolors,
          showhistorycolor: true,
          historycolornum: 16,
          format: 'hex',
          showPalette: true,
          show: false,
          lang: 'en',
          //colorTypeOption: 'single',
          colorTypeOption:'single',
          canMove: true,
          alwaysShow: false,
          autoConfirm: false,
          onError: function (e) {

          },
          onCancel: function (color) {
            console.log("cancel", color)
          },
          onChange: function (color) {
            console.log("change", color)
          },
          onConfirm: function (color) {

            console.log("confirm", color);
            var _color;
            if (color) {
                if(color.color.hex === '#00000000'){
                  el.style.setProperty("border-color", "");
                } else {
                  _color = color.color.rgba;
                  el.style.setProperty("border-color", _color, "important");
                }
            } else {
              //el.style.setProperty("background-color", "");
              el.style.setProperty("color", "");
            }
            self.keditor.save();
          }
        })
      }


      // Add debounced save function
      var debouncedSave = self.debounce(function() {
        self.keditor.save();
      }, 750); // Increased debounce time for better performance
      // Add helper function for slider setup
      function setupSlider(sliderNumber, html, el) {
        var sliderClass = sliderNumber ? `slider${sliderNumber}` : 'slider';
        var minusClass = sliderNumber ? `slider-minus${sliderNumber}` : 'slider-minus';
        var plusClass = sliderNumber ? `slider-plus${sliderNumber}` : 'slider-plus';

        // Set initial values
        html.find(`input.${sliderClass}`).each(function() {
          var value = this.value;
          var property = this.getAttribute("data-property");
          var unit = this.getAttribute("data-unit") || "";
          var important = this.getAttribute("data-important") !== "false";

          if (important) {
            el.style.setProperty(property, value + unit, "important");
          } else {
            el.style.setProperty(property, value + unit);
          }
        });

        // Add event listeners with immediate UI update but debounced save
        html.find(`input.${sliderClass}`).on('input', function() {
          changeSliderValues(this, el, false); // Don't save immediately
          debouncedSave(); // Debounced save
        });

        html.find(`.${minusClass}`).on('click', function() {
          var input = html.find(`input.${sliderClass}`)[0];
          input.stepDown();
          changeSliderValues(input, el, false); // Don't save immediately
          debouncedSave(); // Debounced save
        });

        html.find(`.${plusClass}`).on('click', function() {
          var input = html.find(`input.${sliderClass}`)[0];
          input.stepUp();
          changeSliderValues(input, el, false); // Don't save immediately
          debouncedSave(); // Debounced save
        });
      }

      // Helper function for changing slider values
      // Helper function for changing slider values
      function changeSliderValues(input, el, shouldSave) {
        var value = input.value;
        var property = input.getAttribute("data-property");
        var unit = input.getAttribute("data-unit") || "";
        var important = input.getAttribute("data-important") !== "false";

        $(input).parent().find(".slider_counter").html(value + unit);

        if (important) {
          el.style.setProperty(property, value + unit, "important");
        } else {
          el.style.setProperty(property, value + unit);
        }

        // Only save if explicitly requested
        if (shouldSave !== false) {
          //self.keditor.save();
        }
      }

      if (_slider) {
        setupSlider(null, _html, el);
      }
      if (_slider2) {
        setupSlider(2, _html, el);
      }
      if (_slider3) {
        setupSlider(3, _html, el);
      }
      if (_slider4) {
        setupSlider(4, _html, el);
      }


      // text area
      _html.find(".textAreaEmbeddingScript").on('input', function() {
        console.log("text area change", $(this).val());
        // Get the user input from the textarea
        var embedCode = $(this).val();
        function htmlEncode(str) {
          return str.replace(/&/g, '&amp;')
                    .replace(/"/g, '&quot;')
                    .replace(/'/g, '&#39;')
                    .replace(/</g, '&lt;')
                    .replace(/>/g, '&gt;');
        }
        // Encode the embedding code
        var encodedEmbedCode = htmlEncode(embedCode);

        // Set the encoded code in the data attribute
        $(el).attr('data-embedding-script', encodedEmbedCode);
        self.keditor.save();
      });


      // hideable checkbox
      //console.log("input hide", _html.find("input.hide"))
      //console.log("form", form)
      _html.find("input.hide").on('change', function () {

        var test = $(this).is(':checked');


        _hideable = $(el).attr('data-wps-edit-hide');



        if (!this.checked) {

          //$(this).prop("checked", true);
          $(this).removeAttr("checked");
          if (_hideable == "parent") {
            //$(el).parent().css({'cssText': 'display: none!important'});
            $(el).parent().addClass('webmag_hide');
          } else if (_hideable == "true") {
            //$(el).css({'cssText': 'display: none!important'});
            $(el).addClass('webmag_hide');
          } else {
            //$(el).closest(_hideable).css({'cssText': 'display: none!important'});
            $(el).closest(_hideable).addClass('webmag_hide');
          }
          //$(this).closest("ams-fragment").find(".input_froala").hide();
        } else {

          $(this).attr("checked", "checked");
          if (_hideable == "parent") {
            //$(el).parent().show();
            $(el).parent().css("display", "");
            $(el).parent().removeClass('webmag_hide');
          } else if (_hideable == "true") {
            //$(el).show();
            $(el).css("display", "");
            $(el).removeClass('webmag_hide');
          } else {
            //$(el).closest(_hideable).show();
            $(el).closest(_hideable).css("display", "");
            $(el).closest(_hideable).removeClass('webmag_hide');
          }
          //$(this).closest("ams-fragment").find(".input_froala").show();
        }
        self.keditor.save();
      });



      _html.find("input.linkblank").on('change', function () {
        if (this.checked) {
          $(el).attr('target', '_blank');
        } else {
          $(el).attr('target', '');
        }
        self.keditor.save();
      })

      _html.find("input.linkModal").on('change', function () {
        if (this.checked) {
          $(el).attr('data-open-modal', 'true');
          $(el).attr('data-open-modal-fullscreen', 'true');
          $(this).closest("ams-fragment").find(".linkModal2").parent().removeClass("d-none").addClass('d-block');
        } else {
          $(el).removeAttr('data-open-modal');
          $(el).removeAttr('data-open-modal-fullscreen');
          $(this).closest("ams-fragment").find(".linkModal2").parent().removeClass("d-block").addClass('d-none');
        }
        self.keditor.save();
      })
      _html.find("input.linkModal2").on('change', function () {
        if (this.checked) {
          $(el).attr('data-open-modal-fullscreen', 'true');
        } else {
          $(el).removeAttr('data-open-modal-fullscreen');
        }
        self.keditor.save();
      })



      _html.find("select.article_linksChange").on('change', function () {
        var _options = $(this).find("option");



        var _value = this.value;


        var _m_id = $.grep(self.keditor.options.allPageSiblings, function (e) {
          return e.pageId == _value;
        });
        if (_m_id.length > 0) {
          $(el).attr('href', _m_id[0].slug);
          $(this).closest("ams-fragment").find(".ams_link_textarea_href .fr-element").html(_m_id[0].slug)
        } else {
          $(el).attr('href', "");
          $(this).closest("ams-fragment").find(".ams_link_textarea_href .fr-element").html("")
        }
        $(el).removeAttr('data-wps-edit-variable');



        self.keditor.save();
      })


      // custom client classNames
      function checkClassChange(_classChange){
        if(_classChange==="tennet_box"){
          _classChange = "Keine Box=no_tennet_box|Weiße Box=tennet_box-white|Weiße Box (dicker)=tennet_box-big-white|Blaue Box=tennet_box-blue|Blaue Box (dicker)=tennet_box-big-blue|Hellblaue Box=tennet_box-lightblue|Hellblaue Box (dicker)=tennet_box-big-lightblue|Dunkelgraue Box=tennet_box-darkGrey|Dunkelgraue Box (dicker)=tennet_box-big-darkGrey|Seeblaue Box=tennet_box-seaBlue|Seeblaue Box (dicker)=tennet_box-big-seaBlue|Dunkelgrüne Box=tennet_box-darkGreen|Dunkelgrüne Box (dicker)=tennet_box-big-darkGreen|Strahlendblaue Box=tennet_box-brightBlue|Strahlendblaue Box (dicker)=tennet_box-big-brightBlue|Orangene Box=tennet_box-orange|Orangene Box (dicker)=tennet_box-big-orange|Mittelgraue Box=tennet_box-middleGrey|Mittelgraue Box (dicker)=tennet_box-big-middleGrey|Mittelblaue Box=tennet_box-mediumBlue|Mittelblaue Box (dicker)=tennet_box-big-mediumBlue"
        }

        return _classChange;
      }

      _html.find("select.classCustom").on('change', function () {
        var _options = $(this).find("option");
        $.each(_options, function (index, val) {
          /* iterate through array or object */
          $(el).removeClass($(val).val());
        });
        $(el).addClass(this.value);

        self.keditor.save();
      })

      setupClassChangeHandlers(_html);

      //   var _options = $(this).find("option");
      //   $.each(_options, function (index, val) {
      //     /* iterate through array or object */
      //     $(el).removeClass($(val).val());
      //   });

      //   // if el has data attribute data-wps-class-change-attr set value to attribute written in data-wps-class-change-attr
      //   if($(el).attr('data-wps-class-change-attr')){
      //     var _attr = $(el).attr("data-wps-class-change-attr");
      //     if(_attr) $(el).attr(_attr, this.value);
      //   }else{
      //     $(el).addClass(this.value);
      //   }



      //   switch (this.value) {
      //     case "universal_tabs1_js":
      //       $(el).find('.universal_js_video').addClass('col-md-12').removeClass('col-md-9 order-2 order-md-1');
      //       $(el).find('.universal_js_list').addClass('col-md-12').removeClass('col-md-3 order-1 order-md-2');
      //       $(el).find('.universal_js_list .row > div').addClass('col-md-3').removeClass('col-md-12');
      //       break;
      //     case "universal_tabs2_js":
      //       $(el).find('.universal_js_video').removeClass('col-md-12').addClass('col-md-9 order-2 order-md-1');
      //       $(el).find('.universal_js_list').removeClass('col-md-12').addClass('col-md-3 order-1 order-md-2');
      //       $(el).find('.universal_js_list .row > div').removeClass('col-md-3').addClass('col-md-12');
      //       break;
      //     case "cta05_01_js":
      //       $(el).find('.cta05_text').addClass('order-0').removeClass('order-2');
      //       $(el).find('.cta05_image').addClass('mt-3 mt-md-0').removeClass('mb-3 mb-md-0');
      //       break;
      //     case "cta05_02_js":
      //       $(el).find('.cta05_text').addClass('order-2').removeClass('order-0');
      //       $(el).find('.cta05_image').addClass('mb-3 mb-md-0').removeClass('mt-3 mt-md-0');

      //       break;
      //   }

      //   self.keditor.save();
      // })

      // function setupClassChangeHandlers(_html) {
      //   for (var i = 0; i <= 8; i++) {
      //     var className = i === 0 ? 'classChange' : `classChange${i}`;
      //     _html.find('.' + className).on('change', function() {
      //       var select = $(this);
      //       var oldValue = select.find('option').filter(function() {
      //         return $(el).hasClass(this.value);
      //       }).val();

      //       if (oldValue) {
      //         $(el).removeClass(oldValue);
      //       }

      //       var newValue = select.val();
      //       if (newValue) {
      //         $(el).addClass(newValue);
      //         // Handle special class cases
      //         switch (newValue) {
      //           case "universal_tabs1_js":
      //             $(el).find('.universal_js_video').addClass('col-md-12').removeClass('col-md-9 order-2 order-md-1');
      //             $(el).find('.universal_js_list').addClass('col-md-12').removeClass('col-md-3 order-1 order-md-2');
      //             $(el).find('.universal_js_list .row > div').addClass('col-md-3').removeClass('col-md-12');
      //             break;
      //           case "universal_tabs2_js":
      //             $(el).find('.universal_js_video').removeClass('col-md-12').addClass('col-md-9 order-2 order-md-1');
      //             $(el).find('.universal_js_list').removeClass('col-md-12').addClass('col-md-3 order-1 order-md-2');
      //             $(el).find('.universal_js_list .row > div').removeClass('col-md-3').addClass('col-md-12');
      //             break;
      //           case "cta05_01_js":
      //             $(el).find('.cta05_text').addClass('order-0').removeClass('order-2');
      //             $(el).find('.cta05_image').addClass('mt-3 mt-md-0').removeClass('mb-3 mb-md-0');
      //             break;
      //           case "cta05_02_js":
      //             $(el).find('.cta05_text').addClass('order-2').removeClass('order-0');
      //             $(el).find('.cta05_image').addClass('mb-3 mb-md-0').removeClass('mt-3 mt-md-0');
      //             break;
      //         }
      //       }

      //       self.keditor.save();
      //     });
      //   }

      /*_html.find("select.classButton").on('change', function() {
          _classChange = $(el).attr('data-wps-class-buton');
          var _options = $(this).find("option");
          $.each(_options, function(index, val) {
               $(el).removeClass($(val).val());
          });
          $(el).addClass(this.value);

          self.keditor.save();
      })*/

      if (_classButton) {

        var _classnames;

        var _filled = [{
            optgroup: 'Filled',
            value: "btn-white text-primary",
            text: "White",
            class: "bg-white"
          },
          {
            optgroup: 'Filled',
            value: "btn-dark",
            text: "Dark",
            class: "bg-dark"
          }
        ];

        var _outline = [{
            optgroup: 'Outline',
            value: "btn-outline-white",
            text: "White",
            class: "bg-white"
          },
          {
            optgroup: 'Outline',
            value: "btn-outline-dark",
            text: "Dark",
            class: "bg-dark"
          }
        ];

        var _link = [{
            optgroup: 'Link',
            value: "text-white pl-0",
            text: "White",
            class: "bg-white"
          },
          {
            optgroup: 'Link',
            value: "text-dark pl-0",
            text: "Dark",
            class: "bg-dark"
          }
        ];

        var index = 1;

        // add colors from settings
        var _suffix = ["primary", "secondary", "tertiary", "quaternary"]
        $.each(self.keditor.corporateIdentity._customColor, function (key, value) {
          key = _suffix[key];
          if (value != "") {
            _filled.push({
              optgroup: 'Filled',
              value: "btn-" + key + "",
              text: "Color " + index + "",
              class: "bg-" + key + ""
            })
            _outline.push({
              optgroup: 'Outline',
              value: "btn-outline-" + key + "",
              text: "Color " + index + "",
              class: "bg-" + key + ""
            })
            _link.push({
              optgroup: 'Link',
              value: "text-" + key + " pl-0",
              text: "Color " + index + "",
              class: "bg-" + key + ""
            })
          }
          index++;

        });

        var _options = [];
        _item = "";
        _options = _options.concat(_filled, _outline, _link);

        //get initial selected value
        $.each(_options, function (index, val) {
          if ($(el).hasClass(val.value)) {
            _item = val.value;
            return false;
          }
        })

        console.log("_options", _options)

        console.log("self.keditor.groupSettings.editorSettings", self.keditor.groupSettings.editorSettings)
        if(self.keditor.groupSettings.editorSettings && self.keditor.groupSettings.editorSettings.buttonClasses){
          // add array to _options
          _options = _options.concat(self.keditor.groupSettings.editorSettings.buttonClasses);
        }
        console.log("_options", _options)


        _selectize = _html.find("select.classButton").selectize({
          plugins: ['optgroup_columns'],
          maxItems: 1,
          items: [_item],
          preload: true,
          options: _options,
          optgroups: [{
              value: 'Filled',
              label: 'Filled',
              class: "btn-dark"
            },
            {
              value: 'Outline',
              label: 'Outline',
              class: "btn-outline-dark"
            },
            {
              value: 'Link',
              label: 'Link'
            }
          ],
          optgroupField: 'optgroup',
          labelField: 'text',
          valueField: 'value',
          render: {
            //<span class="colorSwatch '+data.class+'"></span>
            option: function (data, escape) {
              //console.log(data)
              return '<div class="optionButton option btn ' + data.value + '" data-selectable="" data-value="' + data.value + '">' + data.text + '</div>'
            },
            item: function (data, escape) {
              return '<div>' +
                data.optgroup + ": " + data.text +
                '</div>';
            },
            optgroup_header: function (data, escape) {
              return '<div class="optgroup-header">' + escape(data.label) + '</div>';
            }
          },
          onChange: function (value) {
            var _options = this.options;
            $.each(_options, function (index, val) {
              /* iterate through array or object */
              $(el).removeClass(val.value);
            });

            $(el).addClass(value);

            self.keditor.save();
          },
          onInitialize: function () {
            var selectize = this;
            selectize.setValue(_item, true);
          }
        });


      }


      if (_classSelect) {
        _html.find("select.classSelect").selectize({
          plugins: ['remove_button', 'optgroup_columns'],
          hideSelected: false,
          onChange: function (value) {
            //console.log(value, this);
            var _options = this.options;
            //console.log(_options);
            $.each(_options, function (index, val) {
              //console.log(val)
              /* iterate through array or object */
              $(el).removeClass(val.value);
            });

            $.each(value, function (index, val) {
              //console.log("val", val)
              /* iterate through array or object */
              $(el).addClass(val);
            });



            self.keditor.save();
          }
        });
      }

      //console.error("add iconpicker change event", _html.find(".iconpicker"));
      //console.log("_html", _html)
      _html.find(".iconpicker").iconpicker({
        iconset: 'fontawesome4',
        iconsetVersion: "4.7.0",
        arrowPrevIconClass: 'fa fa-arrow-left',
        arrowNextIconClass: 'fa fa-arrow-right',
        placement: 'right',
        unselectedClass:'',
        selectedClass:'btn-outline-primary'
      });
      _html.find(".iconpicker").on('change', function (e) {
        //$(el).removeClass();
        //console.log("iconpicker change", el, e.icon)
        $(el)[0].classList.remove($(el).attr('data-icon'));
        $(el).addClass("fa " + e.icon);
        $(el).attr('data-icon', e.icon);

        self.keditor.save();

      });

      // autofit checkbox
      _html.find("input.auto_fit").on('change', function () {

        var _el,
          pb,
          _auto_fit = $(el).attr('data-wps-auto-fit');
        if (_auto_fit == "true") {
          _el = $(el).parent();
        } else {
          _el = $(el).closest(_auto_fit);
        }

        if (this.checked) {
          $(this).prop("checked", true);
          $(el).parent().addClass("auto_fit");
          var size = $(el).parent().attr('data-size').split(","),
            _percentage = size[1] / size[0] * 100;
          _el.css({
            paddingBottom: _percentage + '%'
          });
          $(this).closest(".auto_fit_container").find(".adjust_padding").val(Math.round(_percentage * 100) / 100).prop("disabled", true);
          $(this).closest(".auto_fit_container").find(".adjust_padding").closest("t-ffield").addClass('d-none');


        } else {
          $(this).prop("checked", false);
          $(el).parent().removeClass("auto_fit");

          _el.css({
            paddingBottom: ''
          });

          //add the default padding-bottom again
          _pb = $(_el).css('padding-bottom');
          _pb = parseFloat(_pb) / parseFloat($(_el).css('width')) * 100;
          $(this).closest(".auto_fit_container").find(".adjust_padding").val(Math.round(_pb * 100) / 100).prop("disabled", false);
          $(this).closest(".auto_fit_container").find(".adjust_padding").closest("t-ffield").removeClass('d-none');
        }
        self.keditor.save();
      });

      //autofit padding
      _html.find("input.adjust_padding").on('input', self.debounce(function () {
        var _el,
          _val = $(this).val().trim(),
          _auto_fit = $(el).attr('data-wps-auto-fit');
        if (_auto_fit == "true") {
          _el = $(el).parent();
        } else {
          _el = $(el).closest(_auto_fit);
        }

        //$(this).prop("checked", true);
        $(el).parent().removeClass("auto_fit");
        _el.css({
          paddingBottom: _val + '%'
        });

        self.keditor.save();
      }, 500));


      // // image link
      // _html.find("input.image_link").on('change', function () {
      //   var _el,
      //     pb,
      //     _auto_fit = $(el).attr('data-wps-auto-fit');
      //   if (_auto_fit == "true") {
      //     _el = $(el).parent();
      //   } else {
      //     _el = $(el).closest(_auto_fit);
      //   }

      //   if (this.checked) {
      //     $(this).prop("checked", true);
      //     if ($(el).closest("img-cover").length > 0) {
      //       $(el).closest("img-cover").wrap("<span>");
      //       $(el).closest("img-cover").parent().addClass("link_image");
      //     } else {
      //       $(el).wrap("<span>");
      //       $(el).parent().addClass("link_image");
      //     }
      //     $(el).parent().append('<a href="" class="stretched-link"></a>');
      //     $(this).closest(".image_link_container").find(".image_target").closest("t-ffield").removeClass('d-none');
      //     $(this).closest(".image_link_container").find(".image_link_input").closest("t-ffield").removeClass('d-none');


      //   } else {
      //     $(this).prop("checked", false);
      //     $(el).closest(".link_image").children().unwrap();
      //     $(el).closest(".link_image").removeClass("link_image");
      //     $(el).parent().find(".stretched-link").remove();
      //     //$(el).unwrap();
      //     $(this).closest(".image_link_container").find(".image_target").closest("t-ffield").addClass('d-none');
      //     $(this).closest(".image_link_container").find(".image_link_input").closest("t-ffield").addClass('d-none');
      //   }
      //   self.keditor.save();
      // });

      // // image target
      // _html.find("input.image_target").on('change', function () {
      //   var _el,
      //     pb,
      //     _auto_fit = $(el).attr('data-wps-auto-fit');
      //   if (_auto_fit == "true") {
      //     _el = $(el).parent();
      //   } else {
      //     _el = $(el).closest(_auto_fit);
      //   }

      //   if (this.checked) {
      //     $(this).prop("checked", true);
      //     $(el).parent().find(".stretched-link").attr("target","_blank");

      //   } else {
      //     $(this).prop("checked", false);
      //     $(el).parent().find(".stretched-link").removeAttr("target");

      //   }
      //   self.keditor.save();
      // });

      // // image alt test
      // _html.find("input.image_alt").on('change', function () {

      //   var _el = $(el);
      //   if (this.checked) {
      //     $(this).prop("checked", true);
      //     $(this).closest(".image_alt_container").find(".image_alt_input").closest("t-ffield").removeClass('d-none');
      //   } else {
      //     $(this).prop("checked", false);
      //     $(this).closest(".image_alt_container").find(".image_alt_input").closest("t-ffield").addClass('d-none');
      //   }
      //   self.keditor.save();
      // });
      // //autofit padding
      // _html.find("input.image_alt_input").on('input', self.debounce(function () {
      //   var _el,
      //     _val = $(this).val().trim();
      //   $(el).attr("alt", _val);

      //   self.keditor.save();
      // }, 500));

      // _html.find("input.image_open").on('change', function () {

      //   if (this.checked) {
      //     $(this).prop("checked", true);

      //     $(el).closest("img-cover").removeAttr("data-disable");
      //     $(el).closest(".img-helper").removeAttr("data-disable");

      //   } else {
      //     $(this).prop("checked", false);
      //     $(el).closest("img-cover").attr("data-disable", "true");
      //     $(el).closest(".img-helper").attr("data-disable", "true");
      //   }
      //   self.keditor.save();
      // });

      // //autofit padding
      // _html.find("input.image_link_input").on('input', self.debounce(function () {
      //   var _el,
      //     _val = $(this).val().trim();
      //   $(el).parent().find(".stretched-link").attr("href", _val);


      //   self.keditor.save();
      // }, 500));

    },
    debounce: function debounce(func, wait, immediate) {
      var timeout;
      return function bounce(...args) {
        var context = this;
        var later = function later() {
          timeout = null;
          if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
      };
    },


    addFroalaEditor: function (el, _textarea, _contentChanged, _br) {

      var self = this;

     // _options = $(el).attr("data-wps-toolbar-items");




      /*var _options = {
        // Pass options.
        key: 'oc1F2vB2A1H2B4C1B6mEZXQUVJb1EZf1IWIAUKLJZMBQuD3E2D1E1C4G1G4F1A10C6',
        //embedlyKey: 'c8ad5986b1a740d69e596399b41dd2aa',
        //embedlyScriptPath: 'https://cdn.embedly.com/widgets/platform.js',
        //scrollableContainer: $(el).closest("body")[0],
        toolbarInline: true,
        attribution: false,
        //toolbarContainer: component,
        pluginsEnabled: _pluginsEnabled,
        toolbarButtons: self.options['toolbarSet7'],
        //toolbarButtons: ['bold','fontAwesome'],
        //htmlDoNotWrapTags: ['h1', 'h2'],
        htmlAllowedEmptyTags: ['textarea', 'a', 'iframe', 'object', 'video', 'style', 'script', '.fa', '.fr-emoticon', '.fr-inner', 'path', 'line', 'hr', 'p', 'br', 'strong', 'i'],
        //htmlUntouched: true,
        dragInline: true,
        keepFormatOnDelete: true,
        fontSizeSelection: true,
        fontSize: ['8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '22', '24', '26', '28', '30', '35', '40', '50', '60', '96'],
        //toolbarVisibleWithoutSelection: true,
        //pastePlain: true,
        imageEditButtons: ['imageAlign', 'imageCaption', 'imageRemove', '|', 'imageLink', 'linkEdit', 'linkRemove', '-', 'imageDisplay', 'imageStyle', 'imageAlt', 'imageSize'],
        imageMove: true,
        imagePaste: false,
        imageResizeWithPercent: true,
        imageRoundPercent: true,
        imageStyles: {
          'rounded': 'Rounded',
          'border-2': 'Bordered',
          'shadow': 'Shadow',
          'shadow-sm': 'Shadow small',
          'hidden-sm-down': 'Hide on mobile'
        },
        imageUpload: false,
        wordPasteKeepFormatting: false,
        wordPasteModal: false,
        pasteAllowedStyleProps: ['wps-*'],

        linkEditButtons: linkEditButtons,
        linkText: true,
        linkConvertEmailAddress: true,
        linkAutoPrefix: '',
        paragraphFormatSelection: true,
        //linkInsertButtons:['linkBack','|', 'linkList'],
        linkInsertButtons: ['linkBack'],
        linkMultipleStyles: true,
        linkStyles: _linkStyles,


        initOnClick: true,
        tableColors: _colorsText,
        colorsText: _colorsText,
        colorsBackground: _colorsText,
        align: 'right',
        colorsStep: 6,
        tableColorsStep: 6,
        colorsHEXInput: _customColor,
        entities: "&shy;",
        language: self.keditor.labels.langCode,
        fontAwesomeTemplate: '<i class="fa fa-[NAME] fr-deletable" aria-hidden="true"></i>',
        //enter: FroalaEditor.ENTER_BR,
        paragraphFormat: _paragraphFormat,
        paragraphStyles: _paragraphStyles,
        inlineClasses: _inlineClasses,
        fontFamily: _customFontsObj,
        fontFamilySelection: true,
        tableStyles: _tableStyles,
        //paragraphFormatSelection: true,

        // Bind events

        // Bind events
        events: {
          contentChanged: function () {

            //console.error("changed")
            // Get editor instance.
            var editor = this;

            var inner,
              _attr;
            var _content = editor.html.get(true);

            // if it is only a <br> clean the string (e.g. used in placeholders attributes in a form)
            if (escape(_content) == "%u200B%3Cbr%3E") _content = "";




            //if _contentChanged = function make callback
            if (typeof _contentChanged === "function") {
              _contentChanged(_content);
            } else {

              // if attribute edit is set, change attribute of element
              if ($(_textarea).hasClass('input_froala2')) {
                _attr = $(el).attr("data-wps-edit-attr2");
              } else if ($(_textarea).hasClass('input_froala3')) {
                _attr = $(el).attr("data-wps-edit-attr3");
              } else {
                _attr = $(el).attr("data-wps-edit-attr");
              }


              //console.log("_attr", _attr)
              if (_attr) {
                $(el).attr(_attr, _content);
              } else {
                el["data-froala.editor"].html.set(_content)
              }


            }

            $(el).trigger('datachange');


            self.keditor.save();


          }
        }
      }*/

      var _options = $.extend(true,{},self.keditor.options.keditorOptions);

      //delete _options.scrollableContainer;
      //delete _options.toolbarButtons;
      delete _options.toolbarInline;
      delete _options.toolbarContainer;
      _options.scrollableContainer='#keditor-setting-forms';
      _options.tooltips= false;
      _options.toolbarSticky= false;
      delete _options.initOnClick;
      _options.events = {
        contentChanged: function () {
          //console.log("contentChanged")
          // Get editor instance.
          var editor = this;

          var inner,
            _attr;
          var _content = editor.html.get(true);
          _content = $("<textarea/>").html(_content).text();



          // if it is only a <br> clean the string (e.g. used in placeholders attributes in a form)
          if (escape(_content) == "%u200B%3Cbr%3E") _content = "";

          //if _contentChanged = function make callback
          if (typeof _contentChanged === "function") {
            _content.replace(/[\u200B-\u200D\uFEFF]/g, '')

            _contentChanged(_content);
          } else {
            // if attribute edit is set, change attribute of element
            if ($(_textarea).hasClass('input_froala2')) {
              _attr = $(el).attr("data-wps-edit-attr2");
            } else if ($(_textarea).hasClass('input_froala3')) {
              _attr = $(el).attr("data-wps-edit-attr3");
            } else {
              _attr = $(el).attr("data-wps-edit-attr");
            }
            if (_attr) {

              $(el).attr(_attr, _content.replace(/[\u200B-\u200D\uFEFF]/g, ''));

            } else {
              el["data-froala.editor"].html.set(_content)
            }
          }

          $(el).trigger('datachange');
          self.keditor.save();
        }
      }








      var _attr = false;
      var _options2 = _options;
      if ($(_textarea).hasClass('input_froala2')) {
        _attr = $(el).attr("data-wps-edit-attr2");
      } else if ($(_textarea).hasClass('input_froala3')) {
        _attr = $(el).attr("data-wps-edit-attr3");
      } else {
        _attr = $(el).attr("data-wps-edit-attr");
      }

      if (_attr) {

        _options2.enter = self.keditor.window.FroalaEditor.ENTER_BR;
        _options2.toolbarButtons = {};
        _options2.keepFormatOnDelete = false;
        _options2.pastePlain = true;
        _options = _options2;
      }

      if ($(el).hasClass("toEmail") || $(el).hasClass("pgs-video")) {
        _options2.enter = self.keditor.window.FroalaEditor.ENTER_BR;
        _options2.toolbarButtons = {};
        _options2.pastePlain = true;
        _options2.keepFormatOnDelete = false;
        _options = _options2;
      }
      if($(_textarea).hasClass("ams_link_textarea_href") ){

        _options2.enter = self.keditor.window.ENTER_BR;
        _options2.toolbarButtons = {};
        _options2.pastePlain = true;
        _options2.keepFormatOnDelete = false;
        _options = _options2;
      }

      if (_br) {
        _options2.enter = self.keditor.window.FroalaEditor.ENTER_BR;
        _options = _options2;
      }
      if ($(el).hasClass("wps-br")) {
        _options2.enter = self.keditor.window.FroalaEditor.ENTER_BR;
        _options = _options2;
      }
      if ($(el)[0].tagName.toLowerCase() == "a") {
        _options2.enter = self.keditor.window.FroalaEditor.ENTER_BR;
        //_options2.toolbarButtons = {};
        _options2.pastePlain = true;
        _options = _options2;
      }





      _editor = new self.keditor.window.FroalaEditor(_textarea, _options);

    }

  };

})(jQuery);
